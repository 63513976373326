import { validate } from 'bitcoin-address-validation';
import { isAddress } from '@ethersproject/address';
import { base58_to_binary } from 'base58-js';

import { BigNumber } from 'bignumber.js';
import { isMainnet } from '../config';

export function validateAddress(addr: string, network: string): boolean {
  switch (network) {
    case 'bitcoin':
      return validate(addr);
    case 'ethereum':
    case 'avalanche':
    case 'polygon':
    case 'arbitrum':
    case 'optimism':
      return isAddress(addr);
    case 'sui':
      const regexSUI = /^0x[a-fA-F0-9]{64}$/;
      return regexSUI.test(addr);
    case 'stellar':
      const regexXLM = /^G[A-Z2-7]{55}$/;
      return regexXLM.test(addr);
    case 'tron':
      const regexTron = /^T[A-Za-z1-9]{33}$/;
      return regexTron.test(addr);
    case 'polymesh':
      try {
        return Boolean(base58_to_binary(addr));
      } catch (e) {
        return false;
      }
    case 'solana':
      return validateSolanaAddress(addr);
    case 'ripple':
      const regexXRP = /^r[1-9A-HJ-NP-Za-km-z]{24,34}$/;
      return regexXRP.test(addr);
    case 'aptos':
      const regexAPT = /^0x[a-f0-9]{64}$/;
      return regexAPT.test(addr);
    case 'cardano':
      const regexADA = /^(addr1|stake1|addr_test1|stake_test1)[0-9a-z]{38,}$/;
      return regexADA.test(addr);
    case 'polkadot':
      const regexDOT = /^(1|5|C)[a-km-zA-HJ-NP-Z1-9]{47}$/;
      return regexDOT.test(addr);
    default:
      return false;
  }
}

function validateSolanaAddress(addr: string): boolean {
  return new RegExp(/^[1-9A-HJ-NP-Za-km-z]{32,44}$/).test(addr);
}

// TODO: this function is serving more than one purpose in an unintuitive way
export function formatNetwork(s: string): string {
  switch (s.toLowerCase()) {
    case 'btc':
      return 'bitcoin';
    case 'eth':
      return 'ethereum';
    case 'polyx':
      return 'polymesh';
    case 'xlm':
      return 'stellar';
    case 'avax':
      return 'avalanche';
    case 'sol':
      return 'solana';
    case 'xrp':
      return 'ripple';
    case 'apt':
      return 'aptos';
    case 'ada':
      return 'cardano';
    case 'dot':
    case 'wnd':
      return 'polkadot';
    case 'bitcoin':
      return 'BTC';
    case 'ethereum':
      return 'ETH';
    case 'polymesh':
      return 'POLYX';
    case 'stellar':
      return 'XLM';
    case 'avalanche':
      return 'AVAX';
    case 'solana':
      return 'SOL';
    case 'ripple':
      return 'XRP';
    case 'aptos':
      return 'APT';
    case 'polkadot':
      return  isMainnet() ? 'DOT' : 'WND';
    case 'cardano':
      return 'ADA';
    default:
      return '';
  }
}

export function conversionFactor(asset: string, fractionDigits: number, network: string | undefined = undefined): number {
  switch (asset.toLowerCase()) {
    case 'eth':
    case 'avax':
      if (network !== 'arbitrum') {
        return 1e-18;
      }
      break;
    case 'btc':
    case 'ltc':
    case 'apt':
        return 1e-8;
    case 'xrp':
    case 'ada':
    case 'polyx':
      return 1e-6;
    case 'xlm':
      return 1e-7;
  }
  if (fractionDigits) {
    return BigNumber(10).pow(-fractionDigits).toNumber();
  }
  return 1;
}

/**
 * @description - Format an integer amount to a decimal amount in base units; e.g. 5000 Satoshi to 0.00005 BTC.
 * @param amount - The amount, given as an integer
 * @param asset - The asset, e.g. 'btc' or 'eth' or 'usdt'
 * @param fractionDigits - The number of digits expected after the decimal. Set to 0 for full precision (default).
 * @param network - The network, e.g. 'bitcoin' or 'ethereum'
 */
export function formatIntegerAmount(
  amount: string | number,
  asset: string,
  fractionDigits: number = 0,
  network: string | undefined = undefined,
): string {
  const res = BigNumber(amount).times(conversionFactor(asset, fractionDigits, network));

  if (fractionDigits <= 0) {
    return res.toString();
  }
  return res.toFormat(fractionDigits);
}
