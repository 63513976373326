import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import OwnerAccounts from './OwnerAccounts';
import { getWalletAssets, getWalletById } from '../../../store/wallets';
import { Loading } from '../../../assets/icons/loading';
import { currentComponent, showModal } from '../../../store/modal';
import AddAsset from './new/AddAsset';
import ManagerList from './ManagerList';
import { enableWallet, disableWallet } from '../../../store/wallets';
import { toast } from 'react-hot-toast';
import ManageManagers from './admin/ManageManagers';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { getAvailableAssets } from '../../../utils/assets';
import { WalletHistoryList } from '../deposits/WalletHistoryList';
import { CopyToClipboard } from '../../../assets/icons/icons';
import { adminAccessBanner } from '../../misc/AdminAccessBanner';

function WalletPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiErrors, setApiErrors] = useState(null);
  const [availableAssets, setAvailableAssets] = useState([]);

  const wallet = useSelector(state => state?.wallets[id]);
  const walletAssets = useSelector(state => state.wallets.assets);
  const { user } = useSelector(state => state.session);

  useEffect(() => {
    const controller = new AbortController();
    dispatch(getWalletById(controller.signal, id))
      .then(res => {
        if (res?.status >= 300) {
          toast.error(`Failed to get wallet data: ${res.data}`);
          navigate('/dashboard/wallets');
        } else {
          setApiErrors(null);
        }
      })
      .catch(console.error); // TODO: this line always throws an error in strict mode because the function is aborted.
    return () => controller.abort();
  }, [dispatch, id, navigate]);

  useEffect(() => {
    setAvailableAssets(getAvailableAssets(walletAssets, wallet?.accounts));
  }, [wallet?.accounts, walletAssets]);

  useEffect(() => {
    dispatch(getWalletAssets({})).catch(e => {
      console.log(e);
      toast.error(`Error getting wallet assets: ${e}`);
    });
  }, [dispatch]);

  if (!wallet) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="space-y-8">
        <WalletHeader wallet={wallet} />
        {adminAccessBanner(user)}
        <WalletManagementButtons wallet={wallet} availableAssets={availableAssets} />
        <WalletAccounts wallet={wallet} apiErrors={apiErrors} />
        <WalletHistoryList walletId={wallet.walletId} />
      </div>
    </div>
  );
}

function WalletHeader({ wallet }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showManagerList = () => {
    dispatch(currentComponent(() => <ManagerList managers={wallet.managers} />));
    dispatch(showModal());
  };

  return (
    <div className="flex space-x-12 justify-between">
      <div className="space-y-2">
        <h1 className="text-2xl">Wallet Details</h1>
        <div
          className="flex items-center space-x-2 hover:cursor-pointer hover:opacity-80"
          onClick={() => navigate('/dashboard/wallets')}
        >
          <ArrowUturnLeftIcon className="w-5" />
          <span>Go back</span>
        </div>
      </div>
      <div className="text-xs lg:text-sm text-gray-400 max-w-fit items-center bg-slate-700 rounded-md px-4 py-1">
        <div className="py-1 flex">
          <span
            className={`col-span-4 bg-red-700 text-white text-xs w-fit p-1 rounded-md ${wallet.active ? 'opacity-0' : ''}`}
          >
            Disabled
          </span>
        </div>
        <table>
          <tbody>
            <tr>
              <td className="pr-2">Wallet ID</td>
              <td>
                <span className="flex text-white col-span-3 space-x-2">
                  <span>{wallet.walletId}</span>
                  <CopyToClipboard label="Wallet ID" text={wallet.walletId} />
                </span>
              </td>
            </tr>
            <tr>
              <td className="pr-2">Owner</td>
              <td>
                <span className="text-white col-span-3">{wallet.owner}</span>
              </td>
            </tr>
            <tr>
              <td className="pr-2">Managers</td>
              <td>
                <span
                  onClick={showManagerList}
                  className="hover:text-green-500 text-green-300 cursor-pointer col-span-3"
                >
                  show
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

function WalletManagementButtons({ wallet, availableAssets }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.session);

  const orgId = user.organization;
  const isManager = user.groups.includes('investors');
  const isAdmin = user.groups.includes('admins');
  const isSuperAdmin = user.groups.includes('superadmins');

  const addRemoveManagers = () => {
    if (!wallet.active) {
      toast.error('Cannot add managers while the wallet is disabled.');
      navigate('/dashboard/wallets');
      return;
    }
    dispatch(
      currentComponent(() => <ManageManagers id={wallet.walletId} managers={wallet.managers} />),
    );
    dispatch(showModal());
  };

  const addNewAsset = () => {
    dispatch(currentComponent(() => <AddAsset id={wallet.walletId} />));
    dispatch(showModal());
  };

  const handleToggleWallet = async () => {
    toast.dismiss();
    if (!wallet || !orgId) {
      return;
    }
    if (wallet.orgId !== orgId) {
      toast.error('Not authorized to perform action in another organization.');
      return;
    }
    const load = toast.loading('Saving');
    try {
      if (wallet.active) {
        await dispatch(disableWallet(wallet.walletId, orgId));
      } else {
        await dispatch(enableWallet(wallet.walletId, orgId));
      }
      toast.dismiss(load);
      toast.success(`Wallet ${wallet.walletId} ${wallet.active ? 'enabled' : 'disabled'}`);
      await dispatch(getWalletById(null, wallet.walletId));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex gap-4 text-xs lg:text-sm text-white">
      <button
        className={`px-3 py-2 rounded hover:shadow-lg disabled:opacity-50 bg-gradient-to-br from-red-500 to-red-600/75 hover:bg-red-400 ${isAdmin || isSuperAdmin ? '' : 'hidden'}`}
        onClick={handleToggleWallet}
        disabled={!isAdmin && !isSuperAdmin}
      >
        {wallet.active ? 'Disable Wallet' : 'Enable Wallet'}
      </button>
      <div className={`flex gap-4 ${wallet.active ? '' : 'hidden'}`}>
        <button
          className="px-3 py-2 rounded text-white hover:shadow-lg disabled:opacity-50 bg-gradient-to-br from-cyan-600 to-cyan-700/75 hover:bg-green-400"
          onClick={addNewAsset}
          disabled={availableAssets.length === 0}
        >
          Add Asset
        </button>
        <button
          className={`px-3 py-2 rounded hover:shadow-lg disabled:opacity-50 bg-gradient-to-br from-cyan-600 to-cyan-700/75 hover:bg-green-400 ${isManager || isAdmin ? '' : 'hidden'}`}
          onClick={addRemoveManagers}
          disabled={!isManager && !isAdmin}
        >
          {isAdmin ? 'Add/Remove Managers' : 'Add Managers'}
        </button>
        <button
          className={`px-3 py-2 rounded text-white hover:shadow-lg disabled:opacity-50 bg-gradient-to-br from-cyan-600 to-cyan-700/75 hover:bg-green-400 ${isManager ? '' : 'hidden'}`}
          disabled={!isManager}
          onClick={() => navigate('create')}
        >
          Create Transaction
        </button>
      </div>
    </div>
  );
}

function WalletAccounts({ wallet, apiErrors }) {
  if (apiErrors) {
    return (
      <div className="flex flex-col items-center text-white text-sm justify-center py-10 space-y-4">
        Error retrieving wallets: Request timed out.
        <button
          className="inline-flex items-start justify-start px-6 py-4 bg-slate-700 hover:bg-slate-600 focus:outline-none rounded text-white"
          onClick={() => window.location.reload()}
        >
          Try Again
        </button>
      </div>
    );
  }
  return <OwnerAccounts wallet={wallet} />;
}

export default WalletPage;
