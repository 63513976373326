/**
 * @description - The SVG for a given network/token pair
 * @param network - e.g. Ethereum
 * @param token - e.g. ETH
 * @param size - Must be either 's' or 'm'
 * @returns {*|null}
 */
export const NetworkLogo = ({ network, token, size }) => {
  token = token?.toUpperCase();
  size = size?.toLowerCase();

  switch (network?.toUpperCase()) {
    case 'ARBITRUM':
      switch (token) {
        case 'ETH':
          return ARBITRUM_ETH[size];
        default:
          return null;
      }

    case 'OPTIMISM':
      switch (token) {
        case 'ETH':
          return OPTIMISM_ETH[size];
        default:
          return null;
      }

    case 'ETHEREUM':
      switch (token) {
        case 'ETH':
          return ETH[size];
        case 'USDT':
          return USDT[size];
        case 'USDC':
          return USDC[size];
        case 'LINK':
          return LINK[size];
        case 'ATOM':
          return ATOM[size];
        case 'UNI':
          return UNI[size];
        default:
          return null;
      }

    case 'POLKADOT':
      switch (token) {
        case 'DOT':
        case 'WND':
          return POLKADOT_DOT[size];
        default:
          return null;
      }
    default:
      break;
  }

  switch (token) {
    case 'BTC':
      return BTC[size];
    case 'AVAX':
      return AVAX[size];
    case 'POLYX':
      return POLYX[size];
    case 'XLM':
      return XLM[size];
    case 'SOL':
      return SOL[size];
    case 'XRP':
      return XRP[size];
    case 'APT':
      return APT[size];
    case 'ADA':
      return ADA[size];
    case 'MATIC':
      return MATIC[size];
    case 'TRX':
      return TRX[size];
    case 'ETH':
      return ETH[size];
    case 'SUI':
      return SUI[size];
    default:
      console.warn('image not found for network', network, 'token', token);
      return null;
  }
};

const BTC = {
  s: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      version="1.1"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 4091.27 4091.73"
    >
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_1421344023328">
          <path
            fill="#F7931A"
            fillRule="nonzero"
            d="M4030.06 2540.77c-273.24,1096.01 -1383.32,1763.02 -2479.46,1489.71 -1095.68,-273.24 -1762.69,-1383.39 -1489.33,-2479.31 273.12,-1096.13 1383.2,-1763.19 2479,-1489.95 1096.06,273.24 1763.03,1383.51 1489.76,2479.57l0.02 -0.02z"
          />
          <path
            fill="white"
            fillRule="nonzero"
            d="M2947.77 1754.38c40.72,-272.26 -166.56,-418.61 -450,-516.24l91.95 -368.8 -224.5 -55.94 -89.51 359.09c-59.02,-14.72 -119.63,-28.59 -179.87,-42.34l90.16 -361.46 -224.36 -55.94 -92 368.68c-48.84,-11.12 -96.81,-22.11 -143.35,-33.69l0.26 -1.16 -309.59 -77.31 -59.72 239.78c0,0 166.56,38.18 163.05,40.53 90.91,22.69 107.35,82.87 104.62,130.57l-104.74 420.15c6.26,1.59 14.38,3.89 23.34,7.49 -7.49,-1.86 -15.46,-3.89 -23.73,-5.87l-146.81 588.57c-11.11,27.62 -39.31,69.07 -102.87,53.33 2.25,3.26 -163.17,-40.72 -163.17,-40.72l-111.46 256.98 292.15 72.83c54.35,13.63 107.61,27.89 160.06,41.3l-92.9 373.03 224.24 55.94 92 -369.07c61.26,16.63 120.71,31.97 178.91,46.43l-91.69 367.33 224.51 55.94 92.89 -372.33c382.82,72.45 670.67,43.24 791.83,-303.02 97.63,-278.78 -4.86,-439.58 -206.26,-544.44 146.69,-33.83 257.18,-130.31 286.64,-329.61l-0.07 -0.05zm-512.93 719.26c-69.38,278.78 -538.76,128.08 -690.94,90.29l123.28 -494.2c152.17,37.99 640.17,113.17 567.67,403.91zm69.43 -723.3c-63.29,253.58 -453.96,124.75 -580.69,93.16l111.77 -448.21c126.73,31.59 534.85,90.55 468.94,355.05l-0.02 0z"
          />
        </g>
      </g>
    </svg>
  ),
  m: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      version="1.1"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 4091.27 4091.73"
    >
      <g id="Layer_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_1421344023328">
          <path
            fill="#F7931A"
            fillRule="nonzero"
            d="M4030.06 2540.77c-273.24,1096.01 -1383.32,1763.02 -2479.46,1489.71 -1095.68,-273.24 -1762.69,-1383.39 -1489.33,-2479.31 273.12,-1096.13 1383.2,-1763.19 2479,-1489.95 1096.06,273.24 1763.03,1383.51 1489.76,2479.57l0.02 -0.02z"
          />
          <path
            fill="white"
            fillRule="nonzero"
            d="M2947.77 1754.38c40.72,-272.26 -166.56,-418.61 -450,-516.24l91.95 -368.8 -224.5 -55.94 -89.51 359.09c-59.02,-14.72 -119.63,-28.59 -179.87,-42.34l90.16 -361.46 -224.36 -55.94 -92 368.68c-48.84,-11.12 -96.81,-22.11 -143.35,-33.69l0.26 -1.16 -309.59 -77.31 -59.72 239.78c0,0 166.56,38.18 163.05,40.53 90.91,22.69 107.35,82.87 104.62,130.57l-104.74 420.15c6.26,1.59 14.38,3.89 23.34,7.49 -7.49,-1.86 -15.46,-3.89 -23.73,-5.87l-146.81 588.57c-11.11,27.62 -39.31,69.07 -102.87,53.33 2.25,3.26 -163.17,-40.72 -163.17,-40.72l-111.46 256.98 292.15 72.83c54.35,13.63 107.61,27.89 160.06,41.3l-92.9 373.03 224.24 55.94 92 -369.07c61.26,16.63 120.71,31.97 178.91,46.43l-91.69 367.33 224.51 55.94 92.89 -372.33c382.82,72.45 670.67,43.24 791.83,-303.02 97.63,-278.78 -4.86,-439.58 -206.26,-544.44 146.69,-33.83 257.18,-130.31 286.64,-329.61l-0.07 -0.05zm-512.93 719.26c-69.38,278.78 -538.76,128.08 -690.94,90.29l123.28 -494.2c152.17,37.99 640.17,113.17 567.67,403.91zm69.43 -723.3c-63.29,253.58 -453.96,124.75 -580.69,93.16l111.77 -448.21c126.73,31.59 534.85,90.55 468.94,355.05l-0.02 0z"
          />
        </g>
      </g>
    </svg>
  ),
};

const AVAX = {
  s: (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1503 1504"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="287" y="258" width="928" height="844" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1502.5 752C1502.5 1166.77 1166.27 1503 751.5 1503C336.734 1503 0.5 1166.77 0.5 752C0.5 337.234 336.734 1 751.5 1C1166.27 1 1502.5 337.234 1502.5 752ZM538.688 1050.86H392.94C362.314 1050.86 347.186 1050.86 337.962 1044.96C327.999 1038.5 321.911 1027.8 321.173 1015.99C320.619 1005.11 328.184 991.822 343.312 965.255L703.182 330.935C718.495 303.999 726.243 290.531 736.021 285.55C746.537 280.2 759.083 280.2 769.599 285.55C779.377 290.531 787.126 303.999 802.438 330.935L876.42 460.079L876.797 460.738C893.336 489.635 901.723 504.289 905.385 519.669C909.443 536.458 909.443 554.169 905.385 570.958C901.695 586.455 893.393 601.215 876.604 630.549L687.573 964.702L687.084 965.558C670.436 994.693 661.999 1009.46 650.306 1020.6C637.576 1032.78 622.263 1041.63 605.474 1046.62C590.161 1050.86 573.004 1050.86 538.688 1050.86ZM906.75 1050.86H1115.59C1146.4 1050.86 1161.9 1050.86 1171.13 1044.78C1181.09 1038.32 1187.36 1027.43 1187.92 1015.63C1188.45 1005.1 1181.05 992.33 1166.55 967.307C1166.05 966.455 1165.55 965.588 1165.04 964.706L1060.43 785.75L1059.24 783.735C1044.54 758.877 1037.12 746.324 1027.59 741.472C1017.08 736.121 1004.71 736.121 994.199 741.472C984.605 746.453 976.857 759.552 961.544 785.934L857.306 964.891L856.949 965.507C841.69 991.847 834.064 1005.01 834.614 1015.81C835.352 1027.62 841.44 1038.5 851.402 1044.96C860.443 1050.86 875.94 1050.86 906.75 1050.86Z"
        fill="#E84142"
      />
    </svg>
  ),
  m: (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 1503 1504"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="287" y="258" width="928" height="844" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1502.5 752C1502.5 1166.77 1166.27 1503 751.5 1503C336.734 1503 0.5 1166.77 0.5 752C0.5 337.234 336.734 1 751.5 1C1166.27 1 1502.5 337.234 1502.5 752ZM538.688 1050.86H392.94C362.314 1050.86 347.186 1050.86 337.962 1044.96C327.999 1038.5 321.911 1027.8 321.173 1015.99C320.619 1005.11 328.184 991.822 343.312 965.255L703.182 330.935C718.495 303.999 726.243 290.531 736.021 285.55C746.537 280.2 759.083 280.2 769.599 285.55C779.377 290.531 787.126 303.999 802.438 330.935L876.42 460.079L876.797 460.738C893.336 489.635 901.723 504.289 905.385 519.669C909.443 536.458 909.443 554.169 905.385 570.958C901.695 586.455 893.393 601.215 876.604 630.549L687.573 964.702L687.084 965.558C670.436 994.693 661.999 1009.46 650.306 1020.6C637.576 1032.78 622.263 1041.63 605.474 1046.62C590.161 1050.86 573.004 1050.86 538.688 1050.86ZM906.75 1050.86H1115.59C1146.4 1050.86 1161.9 1050.86 1171.13 1044.78C1181.09 1038.32 1187.36 1027.43 1187.92 1015.63C1188.45 1005.1 1181.05 992.33 1166.55 967.307C1166.05 966.455 1165.55 965.588 1165.04 964.706L1060.43 785.75L1059.24 783.735C1044.54 758.877 1037.12 746.324 1027.59 741.472C1017.08 736.121 1004.71 736.121 994.199 741.472C984.605 746.453 976.857 759.552 961.544 785.934L857.306 964.891L856.949 965.507C841.69 991.847 834.064 1005.01 834.614 1015.81C835.352 1027.62 841.44 1038.5 851.402 1044.96C860.443 1050.86 875.94 1050.86 906.75 1050.86Z"
        fill="#E84142"
      />
    </svg>
  ),
};

const ETH = {
  s: (
    <div>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 29.999999 30"
        version="1.1"
        id="svg372"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs id="defs340">
          <filter
            id="a"
            width="1.08"
            height="1.0966667"
            x="-0.039999999"
            y="-0.039999999"
            filterUnits="objectBoundingBox"
          >
            <feOffset dy=".5" in="SourceAlpha" result="shadowOffsetOuter1" id="feOffset315" />
            <feGaussianBlur
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
              stdDeviation=".5"
              id="feGaussianBlur317"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
              id="feComposite319"
            />
            <feColorMatrix
              in="shadowBlurOuter1"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.199473505 0"
              id="feColorMatrix321"
            />
          </filter>
        </defs>
        <g id="g4196" transform="translate(-1.2151899,-1.1528724)">
          <circle id="use342" cx="16.200001" cy="16.200001" r="15" fill="none" fillRule="evenodd" />
          <path
            id="use346"
            fill="#fff"
            fillRule="evenodd"
            d="m 16.23125,1.16875 a 15,15 0 0 0 -15,15 15,15 0 0 0 15,15 15,15 0 0 0 15,-15 15,15 0 0 0 -15,-15 z m 0.498047,3 7.496094,12.220703 -7.496094,-3.351562 z M 16.698047,4.2 v 8.869141 L 9.2,16.420703 16.698047,13.071094 24.194141,16.420703 16.698047,20.772266 9.2,16.420703 Z M 9.2,17.815234 l 7.498047,4.351563 v 0.002 L 24.2,17.815234 16.698047,28.194141 Z"
          />
          <path
            opacity="1"
            fill="#f9f9f9"
            fillRule="nonzero"
            fillOpacity="0.2"
            d="m 16.735823,20.723334 7.497,-4.353 -7.497,-3.348 z"
            id="path364"
          />
          <path
            fill="#FFF"
            fillRule="evenodd"
            opacity="0"
            id="use352"
            d="M 16.698,22.168 24.2,17.816 16.698,28.195 9.2,17.815 l 7.498,4.351 z m 0,-17.968 7.497,12.22 -7.497,4.353 L 9.2,16.42 Z"
          />
          <path
            fill="#FFF"
            fillRule="nonzero"
            opacity="0.9"
            d="m 16.692914,4.2195034 -7.4979997,12.2199996 7.4979997,-3.35 z"
            id="path358-0"
          />
          <path
            fill="#FFF"
            opacity="0.5"
            fillRule="nonzero"
            d="m 16.692914,28.200601 v -6.028 l -7.4979996,-4.351 z"
            id="path362-9"
          />
          <path
            fill="#FFF"
            fillRule="nonzero"
            fillOpacity="0.602"
            d="m 9.1949144,16.401492 7.4979996,4.353 v -7.701 z"
            id="path366-7"
          />
        </g>
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-20">
      <svg width="200" height="200" viewBox="0 0 29.999999 30">
        <defs id="defs340">
          <filter
            id="a"
            width="1.08"
            height="1.0966667"
            x="-0.039999999"
            y="-0.039999999"
            filterUnits="objectBoundingBox"
          >
            <feOffset dy=".5" in="SourceAlpha" result="shadowOffsetOuter1" id="feOffset315" />
            <feGaussianBlur
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
              stdDeviation=".5"
              id="feGaussianBlur317"
            />
            <feComposite
              in="shadowBlurOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowBlurOuter1"
              id="feComposite319"
            />
            <feColorMatrix
              in="shadowBlurOuter1"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.199473505 0"
              id="feColorMatrix321"
            />
          </filter>
        </defs>
        <g id="g4196" transform="translate(-1.2151899,-1.1528724)">
          <circle id="use342" cx="16.200001" cy="16.200001" r="15" fill="none" fillRule="evenodd" />
          <path
            id="use346"
            fill="#fff"
            fillRule="evenodd"
            d="m 16.23125,1.16875 a 15,15 0 0 0 -15,15 15,15 0 0 0 15,15 15,15 0 0 0 15,-15 15,15 0 0 0 -15,-15 z m 0.498047,3 7.496094,12.220703 -7.496094,-3.351562 z M 16.698047,4.2 v 8.869141 L 9.2,16.420703 16.698047,13.071094 24.194141,16.420703 16.698047,20.772266 9.2,16.420703 Z M 9.2,17.815234 l 7.498047,4.351563 v 0.002 L 24.2,17.815234 16.698047,28.194141 Z"
          />
          <path
            opacity="1"
            fill="#f9f9f9"
            fillRule="nonzero"
            fillOpacity="0.2"
            d="m 16.735823,20.723334 7.497,-4.353 -7.497,-3.348 z"
            id="path364"
          />
          <path
            fill="#FFF"
            fillRule="evenodd"
            opacity="0"
            id="use352"
            d="M 16.698,22.168 24.2,17.816 16.698,28.195 9.2,17.815 l 7.498,4.351 z m 0,-17.968 7.497,12.22 -7.497,4.353 L 9.2,16.42 Z"
          />
          <path
            fill="#FFF"
            fillRule="nonzero"
            opacity="0.9"
            d="m 16.692914,4.2195034 -7.4979997,12.2199996 7.4979997,-3.35 z"
            id="path358-0"
          />
          <path
            fill="#FFF"
            opacity="0.5"
            fillRule="nonzero"
            d="m 16.692914,28.200601 v -6.028 l -7.4979996,-4.351 z"
            id="path362-9"
          />
          <path
            fill="#FFF"
            fillRule="nonzero"
            fillOpacity="0.602"
            d="m 9.1949144,16.401492 7.4979996,4.353 v -7.701 z"
            id="path366-7"
          />
        </g>
      </svg>
    </div>
  ),
};

const USDT = {
  s: (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="100%" height="100%">
        <path
          d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
          fill="#53ae94"
        />
        <path
          d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
          fill="#fff"
        />
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-20">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="200" height="200">
        <path
          d="M1000,0c552.26,0,1000,447.74,1000,1000S1552.24,2000,1000,2000,0,1552.38,0,1000,447.68,0,1000,0"
          fill="#53ae94"
        />
        <path
          d="M1123.42,866.76V718H1463.6V491.34H537.28V718H877.5V866.64C601,879.34,393.1,934.1,393.1,999.7s208,120.36,484.4,133.14v476.5h246V1132.8c276-12.74,483.48-67.46,483.48-133s-207.48-120.26-483.48-133m0,225.64v-0.12c-6.94.44-42.6,2.58-122,2.58-63.48,0-108.14-1.8-123.88-2.62v0.2C633.34,1081.66,451,1039.12,451,988.22S633.36,894.84,877.62,884V1050.1c16,1.1,61.76,3.8,124.92,3.8,75.86,0,114-3.16,121-3.8V884c243.8,10.86,425.72,53.44,425.72,104.16s-182,93.32-425.72,104.18"
          fill="#fff"
        />
      </svg>
    </div>
  ),
};
const USDC = {
  s: (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="100%" height="100%">
        <path
          d="M1000 2000c554.17 0 1000-445.83 1000-1000S1554.17 0 1000 0 0 445.83 0 1000s445.83 1000 1000 1000z"
          fill="#2775ca"
        />
        <path
          d="M1275 1158.33c0-145.83-87.5-195.83-262.5-216.66-125-16.67-150-50-150-108.34s41.67-95.83 125-95.83c75 0 116.67 25 137.5 87.5 4.17 12.5 16.67 20.83 29.17 20.83h66.66c16.67 0 29.17-12.5 29.17-29.16v-4.17c-16.67-91.67-91.67-162.5-187.5-170.83v-100c0-16.67-12.5-29.17-33.33-33.34h-62.5c-16.67 0-29.17 12.5-33.34 33.34v95.83c-125 16.67-204.16 100-204.16 204.17 0 137.5 83.33 191.66 258.33 212.5 116.67 20.83 154.17 45.83 154.17 112.5s-58.34 112.5-137.5 112.5c-108.34 0-145.84-45.84-158.34-108.34-4.16-16.66-16.66-25-29.16-25h-70.84c-16.66 0-29.16 12.5-29.16 29.17v4.17c16.66 104.16 83.33 179.16 220.83 200v100c0 16.66 12.5 29.16 33.33 33.33h62.5c16.67 0 29.17-12.5 33.34-33.33v-100c125-20.84 208.33-108.34 208.33-220.84z"
          fill="#fff"
        />
        <path
          d="M787.5 1595.83c-325-116.66-491.67-479.16-370.83-800 62.5-175 200-308.33 370.83-370.83 16.67-8.33 25-20.83 25-41.67V325c0-16.67-8.33-29.17-25-33.33-4.17 0-12.5 0-16.67 4.16-395.83 125-612.5 545.84-487.5 941.67 75 233.33 254.17 412.5 487.5 487.5 16.67 8.33 33.34 0 37.5-16.67 4.17-4.16 4.17-8.33 4.17-16.66v-58.34c0-12.5-12.5-29.16-25-37.5zM1229.17 295.83c-16.67-8.33-33.34 0-37.5 16.67-4.17 4.17-4.17 8.33-4.17 16.67v58.33c0 16.67 12.5 33.33 25 41.67 325 116.66 491.67 479.16 370.83 800-62.5 175-200 308.33-370.83 370.83-16.67 8.33-25 20.83-25 41.67V1700c0 16.67 8.33 29.17 25 33.33 4.17 0 12.5 0 16.67-4.16 395.83-125 612.5-545.84 487.5-941.67-75-237.5-258.34-416.67-487.5-491.67z"
          fill="#fff"
        />
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-20">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" width="200" height="200">
        <path
          d="M1000 2000c554.17 0 1000-445.83 1000-1000S1554.17 0 1000 0 0 445.83 0 1000s445.83 1000 1000 1000z"
          fill="#2775ca"
        />
        <path
          d="M1275 1158.33c0-145.83-87.5-195.83-262.5-216.66-125-16.67-150-50-150-108.34s41.67-95.83 125-95.83c75 0 116.67 25 137.5 87.5 4.17 12.5 16.67 20.83 29.17 20.83h66.66c16.67 0 29.17-12.5 29.17-29.16v-4.17c-16.67-91.67-91.67-162.5-187.5-170.83v-100c0-16.67-12.5-29.17-33.33-33.34h-62.5c-16.67 0-29.17 12.5-33.34 33.34v95.83c-125 16.67-204.16 100-204.16 204.17 0 137.5 83.33 191.66 258.33 212.5 116.67 20.83 154.17 45.83 154.17 112.5s-58.34 112.5-137.5 112.5c-108.34 0-145.84-45.84-158.34-108.34-4.16-16.66-16.66-25-29.16-25h-70.84c-16.66 0-29.16 12.5-29.16 29.17v4.17c16.66 104.16 83.33 179.16 220.83 200v100c0 16.66 12.5 29.16 33.33 33.33h62.5c16.67 0 29.17-12.5 33.34-33.33v-100c125-20.84 208.33-108.34 208.33-220.84z"
          fill="#fff"
        />
        <path
          d="M787.5 1595.83c-325-116.66-491.67-479.16-370.83-800 62.5-175 200-308.33 370.83-370.83 16.67-8.33 25-20.83 25-41.67V325c0-16.67-8.33-29.17-25-33.33-4.17 0-12.5 0-16.67 4.16-395.83 125-612.5 545.84-487.5 941.67 75 233.33 254.17 412.5 487.5 487.5 16.67 8.33 33.34 0 37.5-16.67 4.17-4.16 4.17-8.33 4.17-16.66v-58.34c0-12.5-12.5-29.16-25-37.5zM1229.17 295.83c-16.67-8.33-33.34 0-37.5 16.67-4.17 4.17-4.17 8.33-4.17 16.67v58.33c0 16.67 12.5 33.33 25 41.67 325 116.66 491.67 479.16 370.83 800-62.5 175-200 308.33-370.83 370.83-16.67 8.33-25 20.83-25 41.67V1700c0 16.67 8.33 29.17 25 33.33 4.17 0 12.5 0 16.67-4.16 395.83-125 612.5-545.84 487.5-941.67-75-237.5-258.34-416.67-487.5-491.67z"
          fill="#fff"
        />
      </svg>
    </div>
  ),
};

const LINK = {
  s: (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="100%" height="100%">
        <path
          id="Layer_1-2"
          data-name="Layer 1"
          fill="#2a5ada"
          d="M18.9,0l-4,2.3L4,8.6,0,10.9V32.7L4,35l11,6.3,4,2.3,4-2.3L33.8,35l4-2.3V10.9l-4-2.3L22.9,2.3ZM8,28.1V15.5L18.9,9.2l10.9,6.3V28.1L18.9,34.4Z"
        />
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-20">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="200" height="200">
        <path
          id="Layer_1-2"
          data-name="Layer 1"
          fill="#2a5ada"
          d="M18.9,0l-4,2.3L4,8.6,0,10.9V32.7L4,35l11,6.3,4,2.3,4-2.3L33.8,35l4-2.3V10.9l-4-2.3L22.9,2.3ZM8,28.1V15.5L18.9,9.2l10.9,6.3V28.1L18.9,34.4Z"
        />
      </svg>
    </div>
  ),
};

const ATOM = {
  s: (
    <div>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2500 2500"
        width="200"
        height="200"
      >
        <title>cosmos-atom-logo</title>
        <circle cx="1250" cy="1250" r="1250" fill="#2e3148" />
        <circle cx="1250" cy="1250" r="725.31" fill="#1b1e36" />
        <path
          d="M1252.57,159.47c-134.93,0-244.34,489.4-244.34,1093.11s109.41,1093.11,244.34,1093.11,244.34-489.4,244.34-1093.11S1387.5,159.47,1252.57,159.47ZM1269.44,2284c-15.43,20.58-30.86,5.14-30.86,5.14-62.14-72-93.21-205.76-93.21-205.76-108.69-349.79-82.82-1100.82-82.82-1100.82,51.08-596.24,144-737.09,175.62-768.36a19.29,19.29,0,0,1,24.74-2c45.88,32.51,84.36,168.47,84.36,168.47,113.63,421.81,103.34,817.9,103.34,817.9,10.29,344.65-56.94,730.45-56.94,730.45C1341.92,2222.22,1269.44,2284,1269.44,2284Z"
          fill="#6f7390"
        />
        <path
          d="M2200.72,708.59c-67.18-117.08-546.09,31.58-1070,332s-893.47,638.89-826.34,755.92,546.09-31.58,1070-332,893.47-638.89,826.34-755.92h0ZM366.36,1780.45c-25.72-3.24-19.91-24.38-19.91-24.38C378,1666.36,478.4,1572.84,478.4,1572.84c249.43-268.36,913.79-619.65,913.79-619.65,542.54-252.42,711.06-241.77,753.81-230a19.29,19.29,0,0,1,14,20.58c-5.14,56-104.17,157-104.17,157C1746.71,1209.36,1398,1397.58,1398,1397.58c-293.83,180.5-661.93,314.09-661.93,314.09-280.09,100.93-369.7,68.78-369.7,68.78h0Z"
          fill="#6f7390"
        />
        <path
          d="M2198.35,1800.41c67.7-116.77-300.93-456.79-823-759.47S374.43,587.76,306.79,704.73s300.93,456.79,823.3,759.47S2130.71,1917.39,2198.35,1800.41ZM351.65,749.85c-10-23.71,11.11-29.42,11.11-29.42C456.22,702.78,587.5,743,587.5,743c357.15,81.33,994,480.25,994,480.25,490.33,343.11,565.53,494.24,576.8,537.14a19.29,19.29,0,0,1-10.7,22.43c-51.13,23.41-188.07-11.47-188.07-11.47-422.07-113.17-759.62-320.52-759.62-320.52-303.29-163.58-603.19-415.28-603.19-415.28-227.88-191.87-245-285.44-245-285.44Z"
          fill="#6f7390"
        />
        <circle cx="1250" cy="1250" r="128.6" fill="#b7b9c8" />
        <ellipse cx="1777.26" cy="756.17" rx="74.59" ry="77.16" fill="#b7b9c8" />
        <ellipse cx="552.98" cy="1018.52" rx="74.59" ry="77.16" fill="#b7b9c8" />
        <ellipse cx="1098.25" cy="1965.02" rx="74.59" ry="77.16" fill="#b7b9c8" />
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-20">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 2500 2500"
        width="200"
        height="200"
      >
        <title>cosmos-atom-logo</title>
        <circle cx="1250" cy="1250" r="1250" fill="#2e3148" />
        <circle cx="1250" cy="1250" r="725.31" fill="#1b1e36" />
        <path
          d="M1252.57,159.47c-134.93,0-244.34,489.4-244.34,1093.11s109.41,1093.11,244.34,1093.11,244.34-489.4,244.34-1093.11S1387.5,159.47,1252.57,159.47ZM1269.44,2284c-15.43,20.58-30.86,5.14-30.86,5.14-62.14-72-93.21-205.76-93.21-205.76-108.69-349.79-82.82-1100.82-82.82-1100.82,51.08-596.24,144-737.09,175.62-768.36a19.29,19.29,0,0,1,24.74-2c45.88,32.51,84.36,168.47,84.36,168.47,113.63,421.81,103.34,817.9,103.34,817.9,10.29,344.65-56.94,730.45-56.94,730.45C1341.92,2222.22,1269.44,2284,1269.44,2284Z"
          fill="#6f7390"
        />
        <path
          d="M2200.72,708.59c-67.18-117.08-546.09,31.58-1070,332s-893.47,638.89-826.34,755.92,546.09-31.58,1070-332,893.47-638.89,826.34-755.92h0ZM366.36,1780.45c-25.72-3.24-19.91-24.38-19.91-24.38C378,1666.36,478.4,1572.84,478.4,1572.84c249.43-268.36,913.79-619.65,913.79-619.65,542.54-252.42,711.06-241.77,753.81-230a19.29,19.29,0,0,1,14,20.58c-5.14,56-104.17,157-104.17,157C1746.71,1209.36,1398,1397.58,1398,1397.58c-293.83,180.5-661.93,314.09-661.93,314.09-280.09,100.93-369.7,68.78-369.7,68.78h0Z"
          fill="#6f7390"
        />
        <path
          d="M2198.35,1800.41c67.7-116.77-300.93-456.79-823-759.47S374.43,587.76,306.79,704.73s300.93,456.79,823.3,759.47S2130.71,1917.39,2198.35,1800.41ZM351.65,749.85c-10-23.71,11.11-29.42,11.11-29.42C456.22,702.78,587.5,743,587.5,743c357.15,81.33,994,480.25,994,480.25,490.33,343.11,565.53,494.24,576.8,537.14a19.29,19.29,0,0,1-10.7,22.43c-51.13,23.41-188.07-11.47-188.07-11.47-422.07-113.17-759.62-320.52-759.62-320.52-303.29-163.58-603.19-415.28-603.19-415.28-227.88-191.87-245-285.44-245-285.44Z"
          fill="#6f7390"
        />
        <circle cx="1250" cy="1250" r="128.6" fill="#b7b9c8" />
        <ellipse cx="1777.26" cy="756.17" rx="74.59" ry="77.16" fill="#b7b9c8" />
        <ellipse cx="552.98" cy="1018.52" rx="74.59" ry="77.16" fill="#b7b9c8" />
        <ellipse cx="1098.25" cy="1965.02" rx="74.59" ry="77.16" fill="#b7b9c8" />
      </svg>
    </div>
  ),
};

const UNI = {
  s: (
    <div>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -20 240 220"
        width="100%"
        height="100%"
      >
        <path
          fill="#FF007A"
          d="M66,44.1c-2.1-0.3-2.2-0.4-1.2-0.5c1.9-0.3,6.3,0.1,9.4,0.8c7.2,1.7,13.7,6.1,20.6,13.8l1.8,2.1l2.6-0.4
	c11.1-1.8,22.5-0.4,32,4c2.6,1.2,6.7,3.6,7.2,4.2c0.2,0.2,0.5,1.5,0.7,2.8c0.7,4.7,0.4,8.2-1.1,10.9c-0.8,1.5-0.8,1.9-0.3,3.2
	c0.4,1,1.6,1.7,2.7,1.7c2.4,0,4.9-3.8,6.1-9.1l0.5-2.1l0.9,1c5.1,5.7,9.1,13.6,9.7,19.2l0.2,1.5l-0.9-1.3c-1.5-2.3-2.9-3.8-4.8-5.1
	c-3.4-2.3-7-3-16.5-3.5c-8.6-0.5-13.5-1.2-18.3-2.8c-8.2-2.7-12.4-6.2-22.1-19.1c-4.3-5.7-7-8.8-9.7-11.4
	C79.6,48.3,73.7,45.3,66,44.1z"
        />
        <path
          fill="#FF007A"
          d="M140.5,56.8c0.2-3.8,0.7-6.3,1.8-8.6c0.4-0.9,0.8-1.7,0.9-1.7c0.1,0-0.1,0.7-0.4,1.5c-0.8,2.2-0.9,5.3-0.4,8.8
	c0.7,4.5,1,5.1,5.8,10c2.2,2.3,4.8,5.2,5.8,6.4l1.7,2.2l-1.7-1.6c-2.1-2-6.9-5.8-8-6.3c-0.7-0.4-0.8-0.4-1.3,0.1
	c-0.4,0.4-0.5,1-0.5,3.9c-0.1,4.5-0.7,7.3-2.2,10.2c-0.8,1.5-0.9,1.2-0.2-0.5c0.5-1.3,0.6-1.9,0.6-6.2c0-8.7-1-10.8-7.1-14.3
	c-1.5-0.9-4.1-2.2-5.6-2.9c-1.6-0.7-2.8-1.3-2.7-1.3c0.2-0.2,6.1,1.5,8.4,2.5c3.5,1.4,4.1,1.5,4.5,1.4
	C140.2,60.1,140.4,59.3,140.5,56.8z"
        />
        <path
          fill="#FF007A"
          d="M70.1,71.7c-4.2-5.8-6.9-14.8-6.3-21.5l0.2-2.1l1,0.2c1.8,0.3,4.9,1.5,6.4,2.4c4,2.4,5.8,5.7,7.5,13.9
	c0.5,2.4,1.2,5.2,1.5,6.1c0.5,1.5,2.4,5,4,7.2c1.1,1.6,0.4,2.4-2.1,2.2C78.5,79.7,73.4,76.2,70.1,71.7z"
        />
        <path
          fill="#FF007A"
          d="M135.4,115.2c-19.8-8-26.8-14.9-26.8-26.6c0-1.7,0.1-3.1,0.1-3.1c0.1,0,0.8,0.6,1.7,1.3c4,3.2,8.5,4.6,21,6.4
	c7.3,1.1,11.5,1.9,15.3,3.2c12.1,4,19.6,12.2,21.4,23.3c0.5,3.2,0.2,9.3-0.6,12.5c-0.7,2.5-2.7,7.1-3.2,7.2c-0.1,0-0.3-0.5-0.3-1.3
	c-0.2-4.2-2.3-8.2-5.8-11.3C154,123.2,148.6,120.5,135.4,115.2z"
        />
        <path
          fill="#FF007A"
          d="M121.4,118.5c-0.2-1.5-0.7-3.4-1-4.2l-0.5-1.5l0.9,1.1c1.3,1.5,2.3,3.3,3.2,5.8c0.7,1.9,0.7,2.5,0.7,5.6
	c0,3-0.1,3.7-0.7,5.4c-1,2.7-2.2,4.6-4.2,6.7c-3.6,3.7-8.3,5.7-15,6.6c-1.2,0.1-4.6,0.4-7.6,0.6c-7.5,0.4-12.5,1.2-17,2.8
	c-0.6,0.2-1.2,0.4-1.3,0.3c-0.2-0.2,2.9-2,5.4-3.2c3.5-1.7,7.1-2.6,15-4c3.9-0.6,7.9-1.4,8.9-1.8C118.1,135.6,123,127.9,121.4,118.5
	z"
        />
        <path
          fill="#FF007A"
          d="M130.5,134.6c-2.6-5.7-3.2-11.1-1.8-16.2c0.2-0.5,0.4-1,0.6-1c0.2,0,0.8,0.3,1.4,0.7c1.2,0.8,3.7,2.2,10.1,5.7
	c8.1,4.4,12.7,7.8,15.9,11.7c2.8,3.4,4.5,7.3,5.3,12.1c0.5,2.7,0.2,9.2-0.5,11.9c-2.2,8.5-7.2,15.3-14.5,19.2c-1.1,0.6-2,1-2.1,1
	c-0.1,0,0.3-1,0.9-2.2c2.4-5.1,2.7-10,0.9-15.5c-1.1-3.4-3.4-7.5-8-14.4C133.2,139.6,131.9,137.5,130.5,134.6z"
        />
        <path
          fill="#FF007A"
          d="M56,165.2c7.4-6.2,16.5-10.6,24.9-12c3.6-0.6,9.6-0.4,12.9,0.5c5.3,1.4,10.1,4.4,12.6,8.1
	c2.4,3.6,3.5,6.7,4.6,13.6c0.4,2.7,0.9,5.5,1,6.1c0.8,3.6,2.4,6.4,4.4,7.9c3.1,2.3,8.5,2.4,13.8,0.4c0.9-0.3,1.7-0.6,1.7-0.5
	c0.2,0.2-2.5,2-4.3,2.9c-2.5,1.3-4.5,1.7-7.2,1.7c-4.8,0-8.9-2.5-12.2-7.5c-0.7-1-2.1-3.9-3.3-6.6c-3.5-8.1-5.3-10.5-9.4-13.2
	c-3.6-2.3-8.2-2.8-11.7-1.1c-4.6,2.2-5.8,8.1-2.6,11.7c1.3,1.5,3.7,2.7,5.7,3c3.7,0.5,6.9-2.4,6.9-6.1c0-2.4-0.9-3.8-3.3-4.9
	c-3.2-1.4-6.7,0.2-6.6,3.3c0,1.3,0.6,2.1,1.9,2.7c0.8,0.4,0.8,0.4,0.2,0.3c-2.9-0.6-3.6-4.2-1.3-6.5c2.8-2.8,8.7-1.6,10.7,2.3
	c0.8,1.6,0.9,4.8,0.2,6.8c-1.7,4.4-6.5,6.7-11.4,5.4c-3.3-0.9-4.7-1.8-8.7-5.9c-7-7.2-9.7-8.6-19.7-10.1l-1.9-0.3L56,165.2z"
        />
        <path
          fill="#FF007A"
          d="M3.4,4.3c23.3,28.3,59.2,72.3,61,74.7c1.5,2,0.9,3.9-1.6,5.3c-1.4,0.8-4.3,1.6-5.7,1.6c-1.6,0-3.5-0.8-4.8-2.1
	c-0.9-0.9-4.8-6.6-13.6-20.3c-6.7-10.5-12.4-19.2-12.5-19.3C25.8,44,25.8,44,38,65.8C45.7,79.5,48.2,84.4,48.2,85c0,1.3-0.4,2-2,3.8
	c-2.7,3-3.9,6.4-4.8,13.5c-1,7.9-3.7,13.5-11.4,23c-4.5,5.6-5.2,6.6-6.3,8.9c-1.4,2.8-1.8,4.4-2,8c-0.2,3.8,0.2,6.2,1.3,9.8
	c1,3.2,2.1,5.3,4.8,9.4c2.3,3.6,3.7,6.3,3.7,7.3c0,0.8,0.2,0.8,3.8,0c8.6-2,15.7-5.4,19.6-9.6c2.4-2.6,3-4,3-7.6
	c0-2.3-0.1-2.8-0.7-4.2c-1-2.2-2.9-4-7-6.8c-5.4-3.7-7.7-6.7-8.3-10.7c-0.5-3.4,0.1-5.7,3.1-12c3.1-6.5,3.9-9.2,4.4-15.8
	c0.3-4.2,0.8-5.9,2-7.2c1.3-1.4,2.4-1.9,5.5-2.3c5.1-0.7,8.4-2,11-4.5c2.3-2.1,3.3-4.2,3.4-7.3l0.1-2.3L70.1,77C65.4,71.6,0.3,0,0,0
	C-0.1,0,1.5,1.9,3.4,4.3z M34.1,146.5c1.1-1.9,0.5-4.3-1.3-5.5c-1.7-1.1-4.3-0.6-4.3,0.9c0,0.4,0.2,0.8,0.8,1c0.9,0.5,1,1,0.3,2.1
	c-0.7,1.1-0.7,2.1,0.2,2.8C31.2,148.9,33.1,148.3,34.1,146.5z"
        />
        <path
          fill="#FF007A"
          d="M74.6,93.9c-2.4,0.7-4.7,3.3-5.4,5.9c-0.4,1.6-0.2,4.5,0.5,5.4c1.1,1.4,2.1,1.8,4.9,1.8
	c5.5,0,10.2-2.4,10.7-5.3c0.5-2.4-1.6-5.7-4.5-7.2C79.3,93.7,76.2,93.4,74.6,93.9z M81,98.9c0.8-1.2,0.5-2.5-1-3.4
	c-2.7-1.7-6.8-0.3-6.8,2.3c0,1.3,2.1,2.7,4.1,2.7C78.6,100.5,80.4,99.7,81,98.9z"
        />
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-100">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -20 397.7 350"
        width="100"
        height="30"
      >
        <path
          fill="#FF007A"
          d="M66,44.1c-2.1-0.3-2.2-0.4-1.2-0.5c1.9-0.3,6.3,0.1,9.4,0.8c7.2,1.7,13.7,6.1,20.6,13.8l1.8,2.1l2.6-0.4
	c11.1-1.8,22.5-0.4,32,4c2.6,1.2,6.7,3.6,7.2,4.2c0.2,0.2,0.5,1.5,0.7,2.8c0.7,4.7,0.4,8.2-1.1,10.9c-0.8,1.5-0.8,1.9-0.3,3.2
	c0.4,1,1.6,1.7,2.7,1.7c2.4,0,4.9-3.8,6.1-9.1l0.5-2.1l0.9,1c5.1,5.7,9.1,13.6,9.7,19.2l0.2,1.5l-0.9-1.3c-1.5-2.3-2.9-3.8-4.8-5.1
	c-3.4-2.3-7-3-16.5-3.5c-8.6-0.5-13.5-1.2-18.3-2.8c-8.2-2.7-12.4-6.2-22.1-19.1c-4.3-5.7-7-8.8-9.7-11.4
	C79.6,48.3,73.7,45.3,66,44.1z"
        />
        <path
          fill="#FF007A"
          d="M140.5,56.8c0.2-3.8,0.7-6.3,1.8-8.6c0.4-0.9,0.8-1.7,0.9-1.7c0.1,0-0.1,0.7-0.4,1.5c-0.8,2.2-0.9,5.3-0.4,8.8
	c0.7,4.5,1,5.1,5.8,10c2.2,2.3,4.8,5.2,5.8,6.4l1.7,2.2l-1.7-1.6c-2.1-2-6.9-5.8-8-6.3c-0.7-0.4-0.8-0.4-1.3,0.1
	c-0.4,0.4-0.5,1-0.5,3.9c-0.1,4.5-0.7,7.3-2.2,10.2c-0.8,1.5-0.9,1.2-0.2-0.5c0.5-1.3,0.6-1.9,0.6-6.2c0-8.7-1-10.8-7.1-14.3
	c-1.5-0.9-4.1-2.2-5.6-2.9c-1.6-0.7-2.8-1.3-2.7-1.3c0.2-0.2,6.1,1.5,8.4,2.5c3.5,1.4,4.1,1.5,4.5,1.4
	C140.2,60.1,140.4,59.3,140.5,56.8z"
        />
        <path
          fill="#FF007A"
          d="M70.1,71.7c-4.2-5.8-6.9-14.8-6.3-21.5l0.2-2.1l1,0.2c1.8,0.3,4.9,1.5,6.4,2.4c4,2.4,5.8,5.7,7.5,13.9
	c0.5,2.4,1.2,5.2,1.5,6.1c0.5,1.5,2.4,5,4,7.2c1.1,1.6,0.4,2.4-2.1,2.2C78.5,79.7,73.4,76.2,70.1,71.7z"
        />
        <path
          fill="#FF007A"
          d="M135.4,115.2c-19.8-8-26.8-14.9-26.8-26.6c0-1.7,0.1-3.1,0.1-3.1c0.1,0,0.8,0.6,1.7,1.3c4,3.2,8.5,4.6,21,6.4
	c7.3,1.1,11.5,1.9,15.3,3.2c12.1,4,19.6,12.2,21.4,23.3c0.5,3.2,0.2,9.3-0.6,12.5c-0.7,2.5-2.7,7.1-3.2,7.2c-0.1,0-0.3-0.5-0.3-1.3
	c-0.2-4.2-2.3-8.2-5.8-11.3C154,123.2,148.6,120.5,135.4,115.2z"
        />
        <path
          fill="#FF007A"
          d="M121.4,118.5c-0.2-1.5-0.7-3.4-1-4.2l-0.5-1.5l0.9,1.1c1.3,1.5,2.3,3.3,3.2,5.8c0.7,1.9,0.7,2.5,0.7,5.6
	c0,3-0.1,3.7-0.7,5.4c-1,2.7-2.2,4.6-4.2,6.7c-3.6,3.7-8.3,5.7-15,6.6c-1.2,0.1-4.6,0.4-7.6,0.6c-7.5,0.4-12.5,1.2-17,2.8
	c-0.6,0.2-1.2,0.4-1.3,0.3c-0.2-0.2,2.9-2,5.4-3.2c3.5-1.7,7.1-2.6,15-4c3.9-0.6,7.9-1.4,8.9-1.8C118.1,135.6,123,127.9,121.4,118.5
	z"
        />
        <path
          fill="#FF007A"
          d="M130.5,134.6c-2.6-5.7-3.2-11.1-1.8-16.2c0.2-0.5,0.4-1,0.6-1c0.2,0,0.8,0.3,1.4,0.7c1.2,0.8,3.7,2.2,10.1,5.7
	c8.1,4.4,12.7,7.8,15.9,11.7c2.8,3.4,4.5,7.3,5.3,12.1c0.5,2.7,0.2,9.2-0.5,11.9c-2.2,8.5-7.2,15.3-14.5,19.2c-1.1,0.6-2,1-2.1,1
	c-0.1,0,0.3-1,0.9-2.2c2.4-5.1,2.7-10,0.9-15.5c-1.1-3.4-3.4-7.5-8-14.4C133.2,139.6,131.9,137.5,130.5,134.6z"
        />
        <path
          fill="#FF007A"
          d="M56,165.2c7.4-6.2,16.5-10.6,24.9-12c3.6-0.6,9.6-0.4,12.9,0.5c5.3,1.4,10.1,4.4,12.6,8.1
	c2.4,3.6,3.5,6.7,4.6,13.6c0.4,2.7,0.9,5.5,1,6.1c0.8,3.6,2.4,6.4,4.4,7.9c3.1,2.3,8.5,2.4,13.8,0.4c0.9-0.3,1.7-0.6,1.7-0.5
	c0.2,0.2-2.5,2-4.3,2.9c-2.5,1.3-4.5,1.7-7.2,1.7c-4.8,0-8.9-2.5-12.2-7.5c-0.7-1-2.1-3.9-3.3-6.6c-3.5-8.1-5.3-10.5-9.4-13.2
	c-3.6-2.3-8.2-2.8-11.7-1.1c-4.6,2.2-5.8,8.1-2.6,11.7c1.3,1.5,3.7,2.7,5.7,3c3.7,0.5,6.9-2.4,6.9-6.1c0-2.4-0.9-3.8-3.3-4.9
	c-3.2-1.4-6.7,0.2-6.6,3.3c0,1.3,0.6,2.1,1.9,2.7c0.8,0.4,0.8,0.4,0.2,0.3c-2.9-0.6-3.6-4.2-1.3-6.5c2.8-2.8,8.7-1.6,10.7,2.3
	c0.8,1.6,0.9,4.8,0.2,6.8c-1.7,4.4-6.5,6.7-11.4,5.4c-3.3-0.9-4.7-1.8-8.7-5.9c-7-7.2-9.7-8.6-19.7-10.1l-1.9-0.3L56,165.2z"
        />
        <path
          fill="#FF007A"
          d="M3.4,4.3c23.3,28.3,59.2,72.3,61,74.7c1.5,2,0.9,3.9-1.6,5.3c-1.4,0.8-4.3,1.6-5.7,1.6c-1.6,0-3.5-0.8-4.8-2.1
	c-0.9-0.9-4.8-6.6-13.6-20.3c-6.7-10.5-12.4-19.2-12.5-19.3C25.8,44,25.8,44,38,65.8C45.7,79.5,48.2,84.4,48.2,85c0,1.3-0.4,2-2,3.8
	c-2.7,3-3.9,6.4-4.8,13.5c-1,7.9-3.7,13.5-11.4,23c-4.5,5.6-5.2,6.6-6.3,8.9c-1.4,2.8-1.8,4.4-2,8c-0.2,3.8,0.2,6.2,1.3,9.8
	c1,3.2,2.1,5.3,4.8,9.4c2.3,3.6,3.7,6.3,3.7,7.3c0,0.8,0.2,0.8,3.8,0c8.6-2,15.7-5.4,19.6-9.6c2.4-2.6,3-4,3-7.6
	c0-2.3-0.1-2.8-0.7-4.2c-1-2.2-2.9-4-7-6.8c-5.4-3.7-7.7-6.7-8.3-10.7c-0.5-3.4,0.1-5.7,3.1-12c3.1-6.5,3.9-9.2,4.4-15.8
	c0.3-4.2,0.8-5.9,2-7.2c1.3-1.4,2.4-1.9,5.5-2.3c5.1-0.7,8.4-2,11-4.5c2.3-2.1,3.3-4.2,3.4-7.3l0.1-2.3L70.1,77C65.4,71.6,0.3,0,0,0
	C-0.1,0,1.5,1.9,3.4,4.3z M34.1,146.5c1.1-1.9,0.5-4.3-1.3-5.5c-1.7-1.1-4.3-0.6-4.3,0.9c0,0.4,0.2,0.8,0.8,1c0.9,0.5,1,1,0.3,2.1
	c-0.7,1.1-0.7,2.1,0.2,2.8C31.2,148.9,33.1,148.3,34.1,146.5z"
        />
        <path
          fill="#FF007A"
          d="M74.6,93.9c-2.4,0.7-4.7,3.3-5.4,5.9c-0.4,1.6-0.2,4.5,0.5,5.4c1.1,1.4,2.1,1.8,4.9,1.8
	c5.5,0,10.2-2.4,10.7-5.3c0.5-2.4-1.6-5.7-4.5-7.2C79.3,93.7,76.2,93.4,74.6,93.9z M81,98.9c0.8-1.2,0.5-2.5-1-3.4
	c-2.7-1.7-6.8-0.3-6.8,2.3c0,1.3,2.1,2.7,4.1,2.7C78.6,100.5,80.4,99.7,81,98.9z"
        />
      </svg>
    </div>
  ),
};

const POLYX = {
  s: (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="white" />
      <path
        d="M24.7716 18.9618C24.1713 19.4945 23.1939 19.7832 21.792 19.7832H15.5216V29.3374H18.1436V22.2682H22.1208C23.3702 22.2682 24.4634 22.0444 25.3938 21.5887L25.3954 21.5879C26.3422 21.1145 27.0854 20.4487 27.619 19.5912C28.155 18.7296 28.4186 17.7115 28.4186 16.5458C28.4186 15.3862 28.1577 14.2054 27.619 13.3397C27.0853 12.482 26.3419 11.8251 25.3946 11.3699C24.4639 10.8956 23.3703 10.6626 22.1208 10.6626H15.5216V13.1476H21.792C23.1949 13.1476 24.1727 13.4367 24.7728 13.9701C25.3664 14.4978 25.687 15.4454 25.687 16.5458C25.687 17.6151 25.3733 18.4095 24.7716 18.9618Z"
        fill="url(#paint0_linear_2112_20727)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM37.38 20C37.38 29.5987 29.5987 37.38 20 37.38C10.4013 37.38 2.62 29.5987 2.62 20C2.62 10.4013 10.4013 2.62 20 2.62C29.5987 2.62 37.38 10.4013 37.38 20Z"
        fill="url(#paint1_linear_2112_20727)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2112_20727"
          x1="92.3373"
          y1="10.3034"
          x2="92.2666"
          y2="46.2934"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2E72" />
          <stop offset="0.934908" stopColor="#4A125E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2112_20727"
          x1="92.3373"
          y1="10.3034"
          x2="92.2666"
          y2="46.2934"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2E72" />
          <stop offset="0.934908" stopColor="#4A125E" />
        </linearGradient>
      </defs>
    </svg>
  ),
  m: (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="white" />
      <path
        d="M24.7716 18.9618C24.1713 19.4945 23.1939 19.7832 21.792 19.7832H15.5216V29.3374H18.1436V22.2682H22.1208C23.3702 22.2682 24.4634 22.0444 25.3938 21.5887L25.3954 21.5879C26.3422 21.1145 27.0854 20.4487 27.619 19.5912C28.155 18.7296 28.4186 17.7115 28.4186 16.5458C28.4186 15.3862 28.1577 14.2054 27.619 13.3397C27.0853 12.482 26.3419 11.8251 25.3946 11.3699C24.4639 10.8956 23.3703 10.6626 22.1208 10.6626H15.5216V13.1476H21.792C23.1949 13.1476 24.1727 13.4367 24.7728 13.9701C25.3664 14.4978 25.687 15.4454 25.687 16.5458C25.687 17.6151 25.3733 18.4095 24.7716 18.9618Z"
        fill="url(#paint0_linear_2112_20727)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20ZM37.38 20C37.38 29.5987 29.5987 37.38 20 37.38C10.4013 37.38 2.62 29.5987 2.62 20C2.62 10.4013 10.4013 2.62 20 2.62C29.5987 2.62 37.38 10.4013 37.38 20Z"
        fill="url(#paint1_linear_2112_20727)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2112_20727"
          x1="92.3373"
          y1="10.3034"
          x2="92.2666"
          y2="46.2934"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2E72" />
          <stop offset="0.934908" stopColor="#4A125E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2112_20727"
          x1="92.3373"
          y1="10.3034"
          x2="92.2666"
          y2="46.2934"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF2E72" />
          <stop offset="0.934908" stopColor="#4A125E" />
        </linearGradient>
      </defs>
    </svg>
  ),
};

const XLM = {
  s: (
    <div>
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.36 200">
        <path d="M203,26.16l-28.46,14.5-137.43,70a82.49,82.49,0,0,1-.7-10.69A81.87,81.87,0,0,1,158.2,28.6l16.29-8.3,2.43-1.24A100,100,0,0,0,18.18,100q0,3.82.29,7.61a18.19,18.19,0,0,1-9.88,17.58L0,129.57V150l25.29-12.89,0,0,8.19-4.18,8.07-4.11v0L186.43,55l16.28-8.29,33.65-17.15V9.14Z" />
        <path d="M236.36,50,49.78,145,33.5,153.31,0,170.38v20.41l33.27-16.95,28.46-14.5L199.3,89.24A83.45,83.45,0,0,1,200,100,81.87,81.87,0,0,1,78.09,171.36l-1,.53-17.66,9A100,100,0,0,0,218.18,100c0-2.57-.1-5.14-.29-7.68a18.2,18.2,0,0,1,9.87-17.58l8.6-4.38Z" />
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-20">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.36 200">
        <path d="M203,26.16l-28.46,14.5-137.43,70a82.49,82.49,0,0,1-.7-10.69A81.87,81.87,0,0,1,158.2,28.6l16.29-8.3,2.43-1.24A100,100,0,0,0,18.18,100q0,3.82.29,7.61a18.19,18.19,0,0,1-9.88,17.58L0,129.57V150l25.29-12.89,0,0,8.19-4.18,8.07-4.11v0L186.43,55l16.28-8.29,33.65-17.15V9.14Z" />
        <path d="M236.36,50,49.78,145,33.5,153.31,0,170.38v20.41l33.27-16.95,28.46-14.5L199.3,89.24A83.45,83.45,0,0,1,200,100,81.87,81.87,0,0,1,78.09,171.36l-1,.53-17.66,9A100,100,0,0,0,218.18,100c0-2.57-.1-5.14-.29-7.68a18.2,18.2,0,0,1,9.87-17.58l8.6-4.38Z" />
      </svg>
    </div>
  ),
};

const SOL = {
  s: (
    <div>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -20 397.7 350"
        width="100%"
        height="100%"
      >
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="360.8791"
          y1="351.4553"
          x2="141.213"
          y2="-69.2936"
          gradientTransform="matrix(1 0 0 -1 0 314)"
        >
          <stop offset="0" stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <path
          fill="url(#SVGID_1_)"
          d="M64.6,237.9c2.4-2.4,5.7-3.8,9.2-3.8h317.4c5.8,0,8.7,7,4.6,11.1l-62.7,62.7c-2.4,2.4-5.7,3.8-9.2,3.8H6.5
	c-5.8,0-8.7-7-4.6-11.1L64.6,237.9z"
        />
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="264.8291"
          y1="401.6014"
          x2="45.163"
          y2="-19.1475"
          gradientTransform="matrix(1 0 0 -1 0 314)"
        >
          <stop offset="0" stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <path
          fill="url(#SVGID_2_)"
          d="M64.6,3.8C67.1,1.4,70.4,0,73.8,0h317.4c5.8,0,8.7,7,4.6,11.1l-62.7,62.7c-2.4,2.4-5.7,3.8-9.2,3.8H6.5
	c-5.8,0-8.7-7-4.6-11.1L64.6,3.8z"
        />
        <linearGradient
          id="SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1="312.5484"
          y1="376.688"
          x2="92.8822"
          y2="-44.061"
          gradientTransform="matrix(1 0 0 -1 0 314)"
        >
          <stop offset="0" stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <path
          fill="url(#SVGID_3_)"
          d="M333.1,120.1c-2.4-2.4-5.7-3.8-9.2-3.8H6.5c-5.8,0-8.7,7-4.6,11.1l62.7,62.7c2.4,2.4,5.7,3.8,9.2,3.8h317.4
	c5.8,0,8.7-7,4.6-11.1L333.1,120.1z"
        />
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-100">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -20 397.7 350"
        width="200"
        height="130"
      >
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="360.8791"
          y1="351.4553"
          x2="141.213"
          y2="-69.2936"
          gradientTransform="matrix(1 0 0 -1 0 314)"
        >
          <stop offset="0" stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <path
          fill="url(#SVGID_1_)"
          d="M64.6,237.9c2.4-2.4,5.7-3.8,9.2-3.8h317.4c5.8,0,8.7,7,4.6,11.1l-62.7,62.7c-2.4,2.4-5.7,3.8-9.2,3.8H6.5
	c-5.8,0-8.7-7-4.6-11.1L64.6,237.9z"
        />
        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="264.8291"
          y1="401.6014"
          x2="45.163"
          y2="-19.1475"
          gradientTransform="matrix(1 0 0 -1 0 314)"
        >
          <stop offset="0" stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <path
          fill="url(#SVGID_2_)"
          d="M64.6,3.8C67.1,1.4,70.4,0,73.8,0h317.4c5.8,0,8.7,7,4.6,11.1l-62.7,62.7c-2.4,2.4-5.7,3.8-9.2,3.8H6.5
	c-5.8,0-8.7-7-4.6-11.1L64.6,3.8z"
        />
        <linearGradient
          id="SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1="312.5484"
          y1="376.688"
          x2="92.8822"
          y2="-44.061"
          gradientTransform="matrix(1 0 0 -1 0 314)"
        >
          <stop offset="0" stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <path
          fill="url(#SVGID_3_)"
          d="M333.1,120.1c-2.4-2.4-5.7-3.8-9.2-3.8H6.5c-5.8,0-8.7,7-4.6,11.1l62.7,62.7c2.4,2.4,5.7,3.8,9.2,3.8h317.4
	c5.8,0,8.7-7,4.6-11.1L333.1,120.1z"
        />
      </svg>
    </div>
  ),
};

const XRP = {
  s: (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width="100%" height="100%">
        <circle cx="64" cy="64" r="60" fill="#fff" />
        <path
          fill="#71c2ff"
          d="M64,60.79c-4.81,0-9.33-1.87-12.73-5.27L34.38,38.62c-1.17-1.17-1.17-3.07,0-4.24 c1.17-1.17,3.07-1.17,4.24,0l16.89,16.89c2.27,2.27,5.28,3.51,8.49,3.51s6.22-1.25,8.49-3.51l16.89-16.89 c1.17-1.17,3.07-1.17,4.24,0c1.17,1.17,1.17,3.07,0,4.24L76.73,55.51C73.33,58.91,68.81,60.79,64,60.79z"
        />
        <path
          fill="#71c2ff"
          d="M91.5,94.5c-0.77,0-1.54-0.29-2.12-0.88L72.49,76.73c-2.27-2.27-5.28-3.51-8.49-3.51s-6.22,1.25-8.49,3.51 L38.62,93.62c-1.17,1.17-3.07,1.17-4.24,0c-1.17-1.17-1.17-3.07,0-4.24l16.89-16.89c3.4-3.4,7.92-5.27,12.73-5.27 s9.33,1.87,12.73,5.27l16.89,16.89c1.17,1.17,1.17,3.07,0,4.24C93.04,94.21,92.27,94.5,91.5,94.5z"
        />
        <path
          fill="#444b54"
          d="M64,127C29.26,127,1,98.74,1,64S29.26,1,64,1s63,28.26,63,63S98.74,127,64,127z M64,7C32.57,7,7,32.57,7,64 s25.57,57,57,57s57-25.57,57-57S95.43,7,64,7z"
        />
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-20">
      <svg
        width="2500"
        height="2500"
        viewBox="0 0 1503 1504"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="64" cy="64" r="60" fill="#fff" />
        <path
          fill="#71c2ff"
          d="M64,60.79c-4.81,0-9.33-1.87-12.73-5.27L34.38,38.62c-1.17-1.17-1.17-3.07,0-4.24 c1.17-1.17,3.07-1.17,4.24,0l16.89,16.89c2.27,2.27,5.28,3.51,8.49,3.51s6.22-1.25,8.49-3.51l16.89-16.89 c1.17-1.17,3.07-1.17,4.24,0c1.17,1.17,1.17,3.07,0,4.24L76.73,55.51C73.33,58.91,68.81,60.79,64,60.79z"
        />
        <path
          fill="#71c2ff"
          d="M91.5,94.5c-0.77,0-1.54-0.29-2.12-0.88L72.49,76.73c-2.27-2.27-5.28-3.51-8.49-3.51s-6.22,1.25-8.49,3.51 L38.62,93.62c-1.17,1.17-3.07,1.17-4.24,0c-1.17-1.17-1.17-3.07,0-4.24l16.89-16.89c3.4-3.4,7.92-5.27,12.73-5.27 s9.33,1.87,12.73,5.27l16.89,16.89c1.17,1.17,1.17,3.07,0,4.24C93.04,94.21,92.27,94.5,91.5,94.5z"
        />
        <path
          fill="#444b54"
          d="M64,127C29.26,127,1,98.74,1,64S29.26,1,64,1s63,28.26,63,63S98.74,127,64,127z M64,7C32.57,7,7,32.57,7,64 s25.57,57,57,57s57-25.57,57-57S95.43,7,64,7z"
        />
      </svg>
    </div>
  ),
};

const MATIC = {
  s: (
    <div>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 178 161"
        width="100%"
        height="100%"
      >
        <path
          fill="#6C00F6"
          d="M66.8,54.7l-16.7-9.7L0,74.1v58l50.1,29l50.1-29V41.9L128,25.8l27.8,16.1v32.2L128,90.2l-16.7-9.7v25.8
	l16.7,9.7l50.1-29V29L128,0L77.9,29v90.2l-27.8,16.1l-27.8-16.1V86.9l27.8-16.1l16.7,9.7V54.7z"
        />
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-100">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -10 178 161"
        width="200"
        height="130"
      >
        <path
          fill="#6C00F6"
          d="M66.8,54.7l-16.7-9.7L0,74.1v58l50.1,29l50.1-29V41.9L128,25.8l27.8,16.1v32.2L128,90.2l-16.7-9.7v25.8
	l16.7,9.7l50.1-29V29L128,0L77.9,29v90.2l-27.8,16.1l-27.8-16.1V86.9l27.8-16.1l16.7,9.7V54.7z"
        />
      </svg>
    </div>
  ),
};

const TRX = {
  s: (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="100%" height="100%">
        <path
          fill="#ff060a"
          d="M61.55,19.28c-3-2.77-7.15-7-10.53-10l-.2-.14a3.82,3.82,0,0,0-1.11-.62l0,0C41.56,7,3.63-.09,2.89,0a1.4,1.4,0,0,0-.58.22L2.12.37a2.23,2.23,0,0,0-.52.84l-.05.13v.71l0,.11C5.82,14.05,22.68,53,26,62.14c.2.62.58,1.8,1.29,1.86h.16c.38,0,2-2.14,2-2.14S58.41,26.74,61.34,23a9.46,9.46,0,0,0,1-1.48A2.41,2.41,0,0,0,61.55,19.28ZM36.88,23.37,49.24,13.12l7.25,6.68Zm-4.8-.67L10.8,5.26l34.43,6.35ZM34,27.27l21.78-3.51-24.9,30ZM7.91,7,30.3,26,27.06,53.78Z"
        />
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-100">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="200" height="130">
        <path
          fill="#ff060a"
          d="M61.55,19.28c-3-2.77-7.15-7-10.53-10l-.2-.14a3.82,3.82,0,0,0-1.11-.62l0,0C41.56,7,3.63-.09,2.89,0a1.4,1.4,0,0,0-.58.22L2.12.37a2.23,2.23,0,0,0-.52.84l-.05.13v.71l0,.11C5.82,14.05,22.68,53,26,62.14c.2.62.58,1.8,1.29,1.86h.16c.38,0,2-2.14,2-2.14S58.41,26.74,61.34,23a9.46,9.46,0,0,0,1-1.48A2.41,2.41,0,0,0,61.55,19.28ZM36.88,23.37,49.24,13.12l7.25,6.68Zm-4.8-.67L10.8,5.26l34.43,6.35ZM34,27.27l21.78-3.51-24.9,30ZM7.91,7,30.3,26,27.06,53.78Z"
        />
      </svg>
    </div>
  ),
};

const ADA = {
  s: (
    <div>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -20 397.7 350"
        width="100%"
        height="100%"
      >
        <g id="Layer_2_1_">
          <g id="Layer_1-2">
            <path
              fill="#0033AD"
              d="M102.8,172c-0.8,13.9,9.9,25.8,23.8,26.6c0.5,0,1,0,1.5,0c14,0,25.3-11.3,25.2-25.3c0-14-11.3-25.3-25.3-25.2
			C114.6,148.1,103.5,158.6,102.8,172z"
            />
            <path
              fill="#0033AD"
              d="M8.6,165.5c-4.5-0.3-8.4,3.2-8.6,7.7s3.2,8.4,7.7,8.6c4.5,0.3,8.3-3.2,8.6-7.7
			C16.6,169.6,13.1,165.8,8.6,165.5C8.6,165.5,8.6,165.5,8.6,165.5z"
            />
            <path
              fill="#0033AD"
              d="M101.2,25.4c4-2,5.6-7,3.6-11c-2-4-7-5.6-11-3.6c-4,2-5.6,6.9-3.6,10.9C92.2,25.8,97.1,27.5,101.2,25.4
			C101.1,25.4,101.2,25.4,101.2,25.4z"
            />
            <path
              fill="#0033AD"
              d="M126.8,70.1c6.2-3.1,8.7-10.7,5.6-16.9s-10.7-8.7-16.9-5.6c-6.2,3.1-8.7,10.7-5.6,16.9
			C113,70.7,120.6,73.2,126.8,70.1z"
            />
            <path
              fill="#0033AD"
              d="M40.6,100.8c4.8,3.1,11.2,1.8,14.4-3c3.1-4.8,1.8-11.2-3-14.4c-4.8-3.1-11.2-1.8-14.4,3c0,0,0,0,0,0
			C34.4,91.2,35.8,97.7,40.6,100.8z"
            />
            <path
              fill="#0033AD"
              d="M55.9,161c-7-0.4-12.9,4.9-13.3,11.9s4.9,12.9,11.9,13.3c7,0.4,12.9-4.9,13.3-11.9c0,0,0,0,0,0
			C68.2,167.4,62.9,161.4,55.9,161z"
            />
            <path
              fill="#0033AD"
              d="M42,245.7c-5.1,2.6-7.2,8.8-4.6,14c2.6,5.1,8.8,7.2,14,4.6c5.1-2.6,7.2-8.8,4.6-14c0,0,0,0,0,0
			C53.4,245.2,47.1,243.1,42,245.7C42,245.7,42,245.7,42,245.7z"
            />
            <path
              fill="#0033AD"
              d="M91,134.9c6.9,4.5,16.1,2.6,20.5-4.3c4.5-6.9,2.6-16.1-4.3-20.5c-6.9-4.5-16.1-2.6-20.5,4.3
			C82.2,121.2,84.1,130.4,91,134.9C91,134.9,91,134.9,91,134.9z"
            />
            <path
              fill="#0033AD"
              d="M246.5,69.1c5.8,3.8,13.7,2.2,17.5-3.6s2.2-13.7-3.6-17.5c-5.8-3.8-13.7-2.2-17.5,3.6c0,0,0,0,0,0
			C239,57.5,240.6,65.3,246.5,69.1C246.5,69.1,246.5,69.1,246.5,69.1z"
            />
            <path
              fill="#0033AD"
              d="M272.3,24.6c3.8,2.5,8.8,1.4,11.3-2.4s1.4-8.8-2.4-11.3c-3.8-2.5-8.8-1.4-11.3,2.3
			C267.5,17,268.6,22.1,272.3,24.6C272.3,24.6,272.3,24.6,272.3,24.6z"
            />
            <path
              fill="#0033AD"
              d="M248.4,147.9c-13.9-0.8-25.9,9.9-26.6,23.8c-0.8,13.9,9.9,25.9,23.8,26.6c0.5,0,1,0,1.4,0
			c13.9,0,25.2-11.3,25.2-25.3C272.3,159.7,261.8,148.6,248.4,147.9L248.4,147.9z"
            />
            <path
              fill="#0033AD"
              d="M135.1,133.1c4.3,8.5,13,13.9,22.6,13.9c13.9,0,25.2-11.3,25.2-25.3c0-3.9-0.9-7.8-2.7-11.4
			c-6.3-12.5-21.5-17.5-33.9-11.2C133.8,105.5,128.8,120.7,135.1,133.1L135.1,133.1z"
            />
            <path
              fill="#0033AD"
              d="M333,100.8c5.1-2.6,7.1-8.9,4.5-14c-2.6-5.1-8.9-7.1-14-4.5c-5.1,2.6-7.1,8.8-4.6,13.9
			C321.6,101.3,327.8,103.4,333,100.8C333,100.8,333,100.8,333,100.8z"
            />
            <path
              fill="#0033AD"
              d="M269,108.8c-7.3,3.7-10.3,12.6-6.6,19.9c3.7,7.3,12.6,10.3,19.9,6.6c7.3-3.7,10.3-12.6,6.6-19.9
			C285.2,108.1,276.3,105.2,269,108.8z"
            />
            <path
              fill="#0033AD"
              d="M186.5,20.8c5.7,0.3,10.6-4.1,11-9.8s-4.1-10.6-9.8-11c-5.7-0.3-10.6,4-11,9.7
			C176.4,15.5,180.8,20.4,186.5,20.8C186.5,20.8,186.5,20.8,186.5,20.8z"
            />
            <path
              fill="#0033AD"
              d="M186.4,86.1c8.2,0.5,15.2-5.8,15.6-14c0.5-8.2-5.8-15.2-14-15.6c-8.2-0.5-15.2,5.8-15.6,14
			C172,78.7,178.2,85.7,186.4,86.1C186.4,86.1,186.4,86.1,186.4,86.1z"
            />
            <path
              fill="#0033AD"
              d="M106,237.7c7.3-3.7,10.3-12.6,6.6-19.9c-3.7-7.3-12.6-10.3-19.9-6.6c-7.3,3.7-10.3,12.6-6.6,19.9
			C89.8,238.4,98.7,241.4,106,237.7z"
            />
            <path
              fill="#0033AD"
              d="M196,107.8c-7.6,11.7-4.4,27.3,7.3,34.9c11.7,7.6,27.3,4.4,34.9-7.3c7.6-11.7,4.4-27.3-7.3-34.9
			c-4.1-2.7-8.9-4.1-13.8-4.1C208.6,96.4,200.7,100.7,196,107.8z"
            />
            <path
              fill="#0033AD"
              d="M239.9,213.4c-6.3-12.5-21.5-17.5-33.9-11.2c-12.5,6.3-17.5,21.5-11.2,33.9c6.3,12.5,21.5,17.5,33.9,11.2
			c0,0,0,0,0,0c12.4-6.2,17.5-21.2,11.3-33.7C240,213.5,240,213.5,239.9,213.4z"
            />
            <path
              fill="#0033AD"
              d="M284,211.6c-6.9-4.5-16.1-2.6-20.5,4.3c-4.5,6.9-2.6,16.1,4.3,20.5c6.9,4.5,16.1,2.6,20.5-4.3
			C292.8,225.3,290.9,216.1,284,211.6C284,211.6,284,211.6,284,211.6z"
            />
            <path
              fill="#0033AD"
              d="M332.4,173.7c0.4-7-4.9-12.9-11.9-13.3c-7-0.4-12.9,4.9-13.3,11.9c-0.4,7,4.9,12.9,11.9,13.3c0,0,0,0,0,0
			C326,186,332,180.6,332.4,173.7z"
            />
            <path
              fill="#0033AD"
              d="M367.3,164.7c-4.5-0.3-8.4,3.2-8.6,7.7s3.2,8.4,7.7,8.6c4.5,0.3,8.3-3.2,8.6-7.7
			C375.2,168.8,371.8,165,367.3,164.7z"
            />
            <path
              fill="#0033AD"
              d="M334.4,245.7c-4.8-3.1-11.2-1.8-14.4,3c-3.1,4.8-1.8,11.2,3,14.4c4.8,3.1,11.2,1.8,14.4-3
			C340.6,255.3,339.2,248.8,334.4,245.7C334.4,245.7,334.4,245.7,334.4,245.7z"
            />
            <path
              fill="#0033AD"
              d="M102.6,321.9c-3.8-2.5-8.8-1.4-11.3,2.3c-2.5,3.8-1.4,8.8,2.3,11.3c3.8,2.5,8.8,1.4,11.3-2.3c0,0,0,0,0,0
			C107.5,329.5,106.4,324.4,102.6,321.9z"
            />
            <path
              fill="#0033AD"
              d="M273.8,321.1c-4,2-5.6,7-3.6,11c2,4,7,5.6,11,3.6c4-2,5.6-6.9,3.6-10.9C282.8,320.7,277.9,319,273.8,321.1
			C273.9,321.1,273.8,321.1,273.8,321.1z"
            />
            <path
              fill="#0033AD"
              d="M179,238.7c7.6-11.7,4.4-27.3-7.3-35c-11.7-7.6-27.3-4.4-35,7.3s-4.4,27.3,7.3,35c4.1,2.7,8.9,4.1,13.8,4.1
			C166.4,250.2,174.3,245.9,179,238.7z"
            />
            <path
              fill="#0033AD"
              d="M128.5,277.4c-5.8-3.8-13.7-2.2-17.5,3.6c-3.8,5.8-2.2,13.7,3.6,17.5s13.7,2.2,17.5-3.6c0,0,0,0,0,0
			C136,289.1,134.4,281.2,128.5,277.4z"
            />
            <path
              fill="#0033AD"
              d="M187.4,325.7c-5.7-0.3-10.6,4.1-11,9.8s4.1,10.6,9.8,11c5.7,0.3,10.6-4,11-9.7
			C197.5,331,193.1,326.1,187.4,325.7C187.4,325.7,187.4,325.7,187.4,325.7z"
            />
            <path
              fill="#0033AD"
              d="M187.5,260.4c-8.2-0.5-15.2,5.8-15.6,14c-0.5,8.2,5.8,15.2,14,15.6c8.2,0.4,15.2-5.8,15.6-14
			C202,267.9,195.7,260.8,187.5,260.4C187.5,260.4,187.5,260.4,187.5,260.4z"
            />
            <path
              fill="#0033AD"
              d="M248.2,276.4c-6.2,3.2-8.7,10.8-5.5,17c3.2,6.2,10.8,8.7,17,5.5c6.2-3.1,8.7-10.7,5.6-16.9
			C262.1,275.8,254.5,273.2,248.2,276.4C248.2,276.4,248.2,276.4,248.2,276.4z"
            />
          </g>
        </g>
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-100">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -20 397.7 350"
        width="200"
        height="130"
      >
        <g id="Layer_2_1_">
          <g id="Layer_1-2">
            <path
              fill="#0033AD"
              d="M102.8,172c-0.8,13.9,9.9,25.8,23.8,26.6c0.5,0,1,0,1.5,0c14,0,25.3-11.3,25.2-25.3c0-14-11.3-25.3-25.3-25.2
			C114.6,148.1,103.5,158.6,102.8,172z"
            />
            <path
              fill="#0033AD"
              d="M8.6,165.5c-4.5-0.3-8.4,3.2-8.6,7.7s3.2,8.4,7.7,8.6c4.5,0.3,8.3-3.2,8.6-7.7
			C16.6,169.6,13.1,165.8,8.6,165.5C8.6,165.5,8.6,165.5,8.6,165.5z"
            />
            <path
              fill="#0033AD"
              d="M101.2,25.4c4-2,5.6-7,3.6-11c-2-4-7-5.6-11-3.6c-4,2-5.6,6.9-3.6,10.9C92.2,25.8,97.1,27.5,101.2,25.4
			C101.1,25.4,101.2,25.4,101.2,25.4z"
            />
            <path
              fill="#0033AD"
              d="M126.8,70.1c6.2-3.1,8.7-10.7,5.6-16.9s-10.7-8.7-16.9-5.6c-6.2,3.1-8.7,10.7-5.6,16.9
			C113,70.7,120.6,73.2,126.8,70.1z"
            />
            <path
              fill="#0033AD"
              d="M40.6,100.8c4.8,3.1,11.2,1.8,14.4-3c3.1-4.8,1.8-11.2-3-14.4c-4.8-3.1-11.2-1.8-14.4,3c0,0,0,0,0,0
			C34.4,91.2,35.8,97.7,40.6,100.8z"
            />
            <path
              fill="#0033AD"
              d="M55.9,161c-7-0.4-12.9,4.9-13.3,11.9s4.9,12.9,11.9,13.3c7,0.4,12.9-4.9,13.3-11.9c0,0,0,0,0,0
			C68.2,167.4,62.9,161.4,55.9,161z"
            />
            <path
              fill="#0033AD"
              d="M42,245.7c-5.1,2.6-7.2,8.8-4.6,14c2.6,5.1,8.8,7.2,14,4.6c5.1-2.6,7.2-8.8,4.6-14c0,0,0,0,0,0
			C53.4,245.2,47.1,243.1,42,245.7C42,245.7,42,245.7,42,245.7z"
            />
            <path
              fill="#0033AD"
              d="M91,134.9c6.9,4.5,16.1,2.6,20.5-4.3c4.5-6.9,2.6-16.1-4.3-20.5c-6.9-4.5-16.1-2.6-20.5,4.3
			C82.2,121.2,84.1,130.4,91,134.9C91,134.9,91,134.9,91,134.9z"
            />
            <path
              fill="#0033AD"
              d="M246.5,69.1c5.8,3.8,13.7,2.2,17.5-3.6s2.2-13.7-3.6-17.5c-5.8-3.8-13.7-2.2-17.5,3.6c0,0,0,0,0,0
			C239,57.5,240.6,65.3,246.5,69.1C246.5,69.1,246.5,69.1,246.5,69.1z"
            />
            <path
              fill="#0033AD"
              d="M272.3,24.6c3.8,2.5,8.8,1.4,11.3-2.4s1.4-8.8-2.4-11.3c-3.8-2.5-8.8-1.4-11.3,2.3
			C267.5,17,268.6,22.1,272.3,24.6C272.3,24.6,272.3,24.6,272.3,24.6z"
            />
            <path
              fill="#0033AD"
              d="M248.4,147.9c-13.9-0.8-25.9,9.9-26.6,23.8c-0.8,13.9,9.9,25.9,23.8,26.6c0.5,0,1,0,1.4,0
			c13.9,0,25.2-11.3,25.2-25.3C272.3,159.7,261.8,148.6,248.4,147.9L248.4,147.9z"
            />
            <path
              fill="#0033AD"
              d="M135.1,133.1c4.3,8.5,13,13.9,22.6,13.9c13.9,0,25.2-11.3,25.2-25.3c0-3.9-0.9-7.8-2.7-11.4
			c-6.3-12.5-21.5-17.5-33.9-11.2C133.8,105.5,128.8,120.7,135.1,133.1L135.1,133.1z"
            />
            <path
              fill="#0033AD"
              d="M333,100.8c5.1-2.6,7.1-8.9,4.5-14c-2.6-5.1-8.9-7.1-14-4.5c-5.1,2.6-7.1,8.8-4.6,13.9
			C321.6,101.3,327.8,103.4,333,100.8C333,100.8,333,100.8,333,100.8z"
            />
            <path
              fill="#0033AD"
              d="M269,108.8c-7.3,3.7-10.3,12.6-6.6,19.9c3.7,7.3,12.6,10.3,19.9,6.6c7.3-3.7,10.3-12.6,6.6-19.9
			C285.2,108.1,276.3,105.2,269,108.8z"
            />
            <path
              fill="#0033AD"
              d="M186.5,20.8c5.7,0.3,10.6-4.1,11-9.8s-4.1-10.6-9.8-11c-5.7-0.3-10.6,4-11,9.7
			C176.4,15.5,180.8,20.4,186.5,20.8C186.5,20.8,186.5,20.8,186.5,20.8z"
            />
            <path
              fill="#0033AD"
              d="M186.4,86.1c8.2,0.5,15.2-5.8,15.6-14c0.5-8.2-5.8-15.2-14-15.6c-8.2-0.5-15.2,5.8-15.6,14
			C172,78.7,178.2,85.7,186.4,86.1C186.4,86.1,186.4,86.1,186.4,86.1z"
            />
            <path
              fill="#0033AD"
              d="M106,237.7c7.3-3.7,10.3-12.6,6.6-19.9c-3.7-7.3-12.6-10.3-19.9-6.6c-7.3,3.7-10.3,12.6-6.6,19.9
			C89.8,238.4,98.7,241.4,106,237.7z"
            />
            <path
              fill="#0033AD"
              d="M196,107.8c-7.6,11.7-4.4,27.3,7.3,34.9c11.7,7.6,27.3,4.4,34.9-7.3c7.6-11.7,4.4-27.3-7.3-34.9
			c-4.1-2.7-8.9-4.1-13.8-4.1C208.6,96.4,200.7,100.7,196,107.8z"
            />
            <path
              fill="#0033AD"
              d="M239.9,213.4c-6.3-12.5-21.5-17.5-33.9-11.2c-12.5,6.3-17.5,21.5-11.2,33.9c6.3,12.5,21.5,17.5,33.9,11.2
			c0,0,0,0,0,0c12.4-6.2,17.5-21.2,11.3-33.7C240,213.5,240,213.5,239.9,213.4z"
            />
            <path
              fill="#0033AD"
              d="M284,211.6c-6.9-4.5-16.1-2.6-20.5,4.3c-4.5,6.9-2.6,16.1,4.3,20.5c6.9,4.5,16.1,2.6,20.5-4.3
			C292.8,225.3,290.9,216.1,284,211.6C284,211.6,284,211.6,284,211.6z"
            />
            <path
              fill="#0033AD"
              d="M332.4,173.7c0.4-7-4.9-12.9-11.9-13.3c-7-0.4-12.9,4.9-13.3,11.9c-0.4,7,4.9,12.9,11.9,13.3c0,0,0,0,0,0
			C326,186,332,180.6,332.4,173.7z"
            />
            <path
              fill="#0033AD"
              d="M367.3,164.7c-4.5-0.3-8.4,3.2-8.6,7.7s3.2,8.4,7.7,8.6c4.5,0.3,8.3-3.2,8.6-7.7
			C375.2,168.8,371.8,165,367.3,164.7z"
            />
            <path
              fill="#0033AD"
              d="M334.4,245.7c-4.8-3.1-11.2-1.8-14.4,3c-3.1,4.8-1.8,11.2,3,14.4c4.8,3.1,11.2,1.8,14.4-3
			C340.6,255.3,339.2,248.8,334.4,245.7C334.4,245.7,334.4,245.7,334.4,245.7z"
            />
            <path
              fill="#0033AD"
              d="M102.6,321.9c-3.8-2.5-8.8-1.4-11.3,2.3c-2.5,3.8-1.4,8.8,2.3,11.3c3.8,2.5,8.8,1.4,11.3-2.3c0,0,0,0,0,0
			C107.5,329.5,106.4,324.4,102.6,321.9z"
            />
            <path
              fill="#0033AD"
              d="M273.8,321.1c-4,2-5.6,7-3.6,11c2,4,7,5.6,11,3.6c4-2,5.6-6.9,3.6-10.9C282.8,320.7,277.9,319,273.8,321.1
			C273.9,321.1,273.8,321.1,273.8,321.1z"
            />
            <path
              fill="#0033AD"
              d="M179,238.7c7.6-11.7,4.4-27.3-7.3-35c-11.7-7.6-27.3-4.4-35,7.3s-4.4,27.3,7.3,35c4.1,2.7,8.9,4.1,13.8,4.1
			C166.4,250.2,174.3,245.9,179,238.7z"
            />
            <path
              fill="#0033AD"
              d="M128.5,277.4c-5.8-3.8-13.7-2.2-17.5,3.6c-3.8,5.8-2.2,13.7,3.6,17.5s13.7,2.2,17.5-3.6c0,0,0,0,0,0
			C136,289.1,134.4,281.2,128.5,277.4z"
            />
            <path
              fill="#0033AD"
              d="M187.4,325.7c-5.7-0.3-10.6,4.1-11,9.8s4.1,10.6,9.8,11c5.7,0.3,10.6-4,11-9.7
			C197.5,331,193.1,326.1,187.4,325.7C187.4,325.7,187.4,325.7,187.4,325.7z"
            />
            <path
              fill="#0033AD"
              d="M187.5,260.4c-8.2-0.5-15.2,5.8-15.6,14c-0.5,8.2,5.8,15.2,14,15.6c8.2,0.4,15.2-5.8,15.6-14
			C202,267.9,195.7,260.8,187.5,260.4C187.5,260.4,187.5,260.4,187.5,260.4z"
            />
            <path
              fill="#0033AD"
              d="M248.2,276.4c-6.2,3.2-8.7,10.8-5.5,17c3.2,6.2,10.8,8.7,17,5.5c6.2-3.1,8.7-10.7,5.6-16.9
			C262.1,275.8,254.5,273.2,248.2,276.4C248.2,276.4,248.2,276.4,248.2,276.4z"
            />
          </g>
        </g>
      </svg>
    </div>
  ),
};

const ARBITRUM_ETH = {
  s: (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 2500 2500"
        width="100%"
        height="100%"
      >
        <rect fill="none" width="2500" height="2500"></rect>
        <g>
          <g>
            <path
              fill="#213147"
              d="M226,760v980c0,63,33,120,88,152l849,490c54,31,121,31,175,0l849-490c54-31,88-89,88-152V760      c0-63-33-120-88-152l-849-490c-54-31-121-31-175,0L314,608c-54,31-87,89-87,152H226z"
            ></path>
            <g>
              <g>
                <g>
                  <path
                    fill="#12AAFF"
                    d="M1435,1440l-121,332c-3,9-3,19,0,29l208,571l241-139l-289-793C1467,1422,1442,1422,1435,1440z"
                  ></path>
                </g>
                <g>
                  <path
                    fill="#12AAFF"
                    d="M1678,882c-7-18-32-18-39,0l-121,332c-3,9-3,19,0,29l341,935l241-139L1678,883V882z"
                  ></path>
                </g>
              </g>
            </g>
            <g>
              <path
                fill="#9DCCED"
                d="M1250,155c6,0,12,2,17,5l918,530c11,6,17,18,17,30v1060c0,12-7,24-17,30l-918,530c-5,3-11,5-17,5       s-12-2-17-5l-918-530c-11-6-17-18-17-30V719c0-12,7-24,17-30l918-530c5-3,11-5,17-5l0,0V155z M1250,0c-33,0-65,8-95,25L237,555       c-59,34-95,96-95,164v1060c0,68,36,130,95,164l918,530c29,17,62,25,95,25s65-8,95-25l918-530c59-34,95-96,95-164V719       c0-68-36-130-95-164L1344,25c-29-17-62-25-95-25l0,0H1250z"
              ></path>
            </g>
            <polygon fill="#213147" points="642,2179 727,1947 897,2088 738,2234     "></polygon>
            <g>
              <path
                fill="#FFFFFF"
                d="M1172,644H939c-17,0-33,11-39,27L401,2039l241,139l550-1507c5-14-5-28-19-28L1172,644z"
              ></path>
              <path
                fill="#FFFFFF"
                d="M1580,644h-233c-17,0-33,11-39,27L738,2233l241,139l620-1701c5-14-5-28-19-28V644z"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-100">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 2500 2500"
      >
        <rect fill="none" width="2500" height="2500"></rect>
        <g>
          <g>
            <path
              fill="#213147"
              d="M226,760v980c0,63,33,120,88,152l849,490c54,31,121,31,175,0l849-490c54-31,88-89,88-152V760      c0-63-33-120-88-152l-849-490c-54-31-121-31-175,0L314,608c-54,31-87,89-87,152H226z"
            ></path>
            <g>
              <g>
                <g>
                  <path
                    fill="#12AAFF"
                    d="M1435,1440l-121,332c-3,9-3,19,0,29l208,571l241-139l-289-793C1467,1422,1442,1422,1435,1440z"
                  ></path>
                </g>
                <g>
                  <path
                    fill="#12AAFF"
                    d="M1678,882c-7-18-32-18-39,0l-121,332c-3,9-3,19,0,29l341,935l241-139L1678,883V882z"
                  ></path>
                </g>
              </g>
            </g>
            <g>
              <path
                fill="#9DCCED"
                d="M1250,155c6,0,12,2,17,5l918,530c11,6,17,18,17,30v1060c0,12-7,24-17,30l-918,530c-5,3-11,5-17,5       s-12-2-17-5l-918-530c-11-6-17-18-17-30V719c0-12,7-24,17-30l918-530c5-3,11-5,17-5l0,0V155z M1250,0c-33,0-65,8-95,25L237,555       c-59,34-95,96-95,164v1060c0,68,36,130,95,164l918,530c29,17,62,25,95,25s65-8,95-25l918-530c59-34,95-96,95-164V719       c0-68-36-130-95-164L1344,25c-29-17-62-25-95-25l0,0H1250z"
              ></path>
            </g>
            <polygon fill="#213147" points="642,2179 727,1947 897,2088 738,2234     "></polygon>
            <g>
              <path
                fill="#FFFFFF"
                d="M1172,644H939c-17,0-33,11-39,27L401,2039l241,139l550-1507c5-14-5-28-19-28L1172,644z"
              ></path>
              <path
                fill="#FFFFFF"
                d="M1580,644h-233c-17,0-33,11-39,27L738,2233l241,139l620-1701c5-14-5-28-19-28V644z"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </div>
  ),
};

const POLKADOT_DOT = {
  s: (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 1400 1400"
        width="100%"
        height="100%"
      >
        <ellipse fill="#E6007A" cx="663" cy="147.9" rx="254.3" ry="147.9" />
        <ellipse fill="#E6007A" cx="663" cy="1262.3" rx="254.3" ry="147.9" />
        <ellipse
          transform="matrix(0.5 -0.866 0.866 0.5 -279.1512 369.5916)"
          fill="#E6007A"
          cx="180.5"
          cy="426.5"
          rx="254.3"
          ry="148"
        />
        <ellipse
          transform="matrix(0.5 -0.866 0.866 0.5 -279.1552 1483.9517)"
          fill="#E6007A"
          cx="1145.6"
          cy="983.7"
          rx="254.3"
          ry="147.9"
        />
        <ellipse
          transform="matrix(0.866 -0.5 0.5 0.866 -467.6798 222.044)"
          fill="#E6007A"
          cx="180.5"
          cy="983.7"
          rx="148"
          ry="254.3"
        />
        <ellipse
          transform="matrix(0.866 -0.5 0.5 0.866 -59.8007 629.9254)"
          fill="#E6007A"
          cx="1145.6"
          cy="426.6"
          rx="147.9"
          ry="254.3"
        />
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-100">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 1400 1400"
      >
        <ellipse fill="#E6007A" cx="663" cy="147.9" rx="254.3" ry="147.9" />
        <ellipse fill="#E6007A" cx="663" cy="1262.3" rx="254.3" ry="147.9" />
        <ellipse
          transform="matrix(0.5 -0.866 0.866 0.5 -279.1512 369.5916)"
          fill="#E6007A"
          cx="180.5"
          cy="426.5"
          rx="254.3"
          ry="148"
        />
        <ellipse
          transform="matrix(0.5 -0.866 0.866 0.5 -279.1552 1483.9517)"
          fill="#E6007A"
          cx="1145.6"
          cy="983.7"
          rx="254.3"
          ry="147.9"
        />
        <ellipse
          transform="matrix(0.866 -0.5 0.5 0.866 -467.6798 222.044)"
          fill="#E6007A"
          cx="180.5"
          cy="983.7"
          rx="148"
          ry="254.3"
        />
        <ellipse
          transform="matrix(0.866 -0.5 0.5 0.866 -59.8007 629.9254)"
          fill="#E6007A"
          cx="1145.6"
          cy="426.6"
          rx="147.9"
          ry="254.3"
        />
      </svg>
    </div>
  ),
};

const SUI = {
  s: (
    <div>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="80%"
        height="100%"
        viewBox="0 0 300 383.5"
      >
        <path
          fill="#4DA2FF"
          fillRule="evenodd"
          d="M240.1,159.9c15.6,19.6,25,44.5,25,71.5s-9.6,52.6-25.7,72.4l-1.4,1.7l-0.4-2.2c-0.3-1.8-0.7-3.7-1.1-5.6
        c-8-35.3-34.2-65.6-77.4-90.2c-29.1-16.5-45.8-36.4-50.2-59c-2.8-14.6-0.7-29.3,3.3-41.9c4.1-12.6,10.1-23.1,15.2-29.4l16.8-20.5
        c2.9-3.6,8.5-3.6,11.4,0L240.1,159.9L240.1,159.9z M266.6,139.4L154.2,2c-2.1-2.6-6.2-2.6-8.3,0L33.4,139.4l-0.4,0.5
        C12.4,165.6,0,198.2,0,233.7c0,82.7,67.2,149.8,150,149.8c82.8,0,150-67.1,150-149.8c0-35.5-12.4-68.1-33.1-93.8L266.6,139.4
        L266.6,139.4z M60.3,159.5l10-12.3l0.3,2.3c0.2,1.8,0.5,3.6,0.9,5.4c6.5,34.1,29.8,62.6,68.6,84.6c33.8,19.2,53.4,41.3,59.1,65.6
        c2.4,10.1,2.8,20.1,1.8,28.8l-0.1,0.5l-0.5,0.2c-15.2,7.4-32.4,11.6-50.5,11.6c-63.5,0-115-51.4-115-114.8
        C34.9,204.2,44.4,179.1,60.3,159.5L60.3,159.5z"
        />
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-100">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 300 383.5">
        <path
          fill="#4DA2FF"
          fillRule="evenodd"
          d="M240.1,159.9c15.6,19.6,25,44.5,25,71.5s-9.6,52.6-25.7,72.4l-1.4,1.7l-0.4-2.2c-0.3-1.8-0.7-3.7-1.1-5.6
        c-8-35.3-34.2-65.6-77.4-90.2c-29.1-16.5-45.8-36.4-50.2-59c-2.8-14.6-0.7-29.3,3.3-41.9c4.1-12.6,10.1-23.1,15.2-29.4l16.8-20.5
        c2.9-3.6,8.5-3.6,11.4,0L240.1,159.9L240.1,159.9z M266.6,139.4L154.2,2c-2.1-2.6-6.2-2.6-8.3,0L33.4,139.4l-0.4,0.5
        C12.4,165.6,0,198.2,0,233.7c0,82.7,67.2,149.8,150,149.8c82.8,0,150-67.1,150-149.8c0-35.5-12.4-68.1-33.1-93.8L266.6,139.4
        L266.6,139.4z M60.3,159.5l10-12.3l0.3,2.3c0.2,1.8,0.5,3.6,0.9,5.4c6.5,34.1,29.8,62.6,68.6,84.6c33.8,19.2,53.4,41.3,59.1,65.6
        c2.4,10.1,2.8,20.1,1.8,28.8l-0.1,0.5l-0.5,0.2c-15.2,7.4-32.4,11.6-50.5,11.6c-63.5,0-115-51.4-115-114.8
        C34.9,204.2,44.4,179.1,60.3,159.5L60.3,159.5z"
        />
      </svg>
    </div>
  ),
};

const APT = {
  s: (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 500 500"
        width="100%"
        height="100%"
      >
        <path d="M387.07,172.13h-42.4c-4.94,0-9.64-2.12-12.91-5.81l-17.2-19.43c-2.56-2.9-6.25-4.55-10.11-4.55s-7.55,1.66-10.11,4.55l-14.75,16.67c-4.83,5.45-11.76,8.58-19.04,8.58H28.46c-6.61,18.84-10.92,38.74-12.64,59.38H234.92c3.85,0,7.54-1.57,10.2-4.35l20.4-21.29c2.55-2.66,6.07-4.16,9.75-4.16h.84c3.87,0,7.55,1.66,10.11,4.56l17.19,19.43c3.27,3.7,7.97,5.81,12.91,5.81h178.84c-1.72-20.65-6.03-40.55-12.64-59.38h-95.46Z" />
        <path d="M148.4,356.39c3.85,0,7.54-1.57,10.2-4.35l20.4-21.29c2.55-2.66,6.07-4.16,9.75-4.16h.84c3.87,0,7.55,1.66,10.11,4.55l17.19,19.43c3.27,3.7,7.97,5.81,12.91,5.81h242.36c9.08-18.76,15.73-38.89,19.69-59.98h-232.63c-4.94,0-9.64-2.12-12.91-5.81l-17.19-19.43c-2.56-2.9-6.25-4.55-10.11-4.55s-7.55,1.66-10.11,4.55l-14.75,16.67c-4.83,5.45-11.76,8.58-19.05,8.58H19.12c3.96,21.09,10.62,41.22,19.69,59.98h109.59Z" />
        <path d="M320.34,107.24c3.85,0,7.54-1.57,10.2-4.35l20.4-21.29c2.55-2.66,6.07-4.16,9.75-4.16h.84c3.87,0,7.55,1.66,10.11,4.56l17.19,19.43c3.27,3.7,7.97,5.81,12.91,5.81h46.09C403.94,48.9,334.13,11.16,255.49,11.16S107.04,48.9,63.15,107.24H320.34Z" />
        <path d="M227.77,415.83h-63.03c-4.94,0-9.64-2.12-12.91-5.81l-17.19-19.43c-2.56-2.9-6.25-4.55-10.11-4.55s-7.55,1.66-10.11,4.55l-14.75,16.67c-4.83,5.45-11.76,8.58-19.05,8.58h-.98c43.91,47.05,106.44,76.5,175.87,76.5s131.95-29.45,175.87-76.5H227.77Z" />
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-100">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 500 500"
      >
        <path d="M387.07,172.13h-42.4c-4.94,0-9.64-2.12-12.91-5.81l-17.2-19.43c-2.56-2.9-6.25-4.55-10.11-4.55s-7.55,1.66-10.11,4.55l-14.75,16.67c-4.83,5.45-11.76,8.58-19.04,8.58H28.46c-6.61,18.84-10.92,38.74-12.64,59.38H234.92c3.85,0,7.54-1.57,10.2-4.35l20.4-21.29c2.55-2.66,6.07-4.16,9.75-4.16h.84c3.87,0,7.55,1.66,10.11,4.56l17.19,19.43c3.27,3.7,7.97,5.81,12.91,5.81h178.84c-1.72-20.65-6.03-40.55-12.64-59.38h-95.46Z" />
        <path d="M148.4,356.39c3.85,0,7.54-1.57,10.2-4.35l20.4-21.29c2.55-2.66,6.07-4.16,9.75-4.16h.84c3.87,0,7.55,1.66,10.11,4.55l17.19,19.43c3.27,3.7,7.97,5.81,12.91,5.81h242.36c9.08-18.76,15.73-38.89,19.69-59.98h-232.63c-4.94,0-9.64-2.12-12.91-5.81l-17.19-19.43c-2.56-2.9-6.25-4.55-10.11-4.55s-7.55,1.66-10.11,4.55l-14.75,16.67c-4.83,5.45-11.76,8.58-19.05,8.58H19.12c3.96,21.09,10.62,41.22,19.69,59.98h109.59Z" />
        <path d="M320.34,107.24c3.85,0,7.54-1.57,10.2-4.35l20.4-21.29c2.55-2.66,6.07-4.16,9.75-4.16h.84c3.87,0,7.55,1.66,10.11,4.56l17.19,19.43c3.27,3.7,7.97,5.81,12.91,5.81h46.09C403.94,48.9,334.13,11.16,255.49,11.16S107.04,48.9,63.15,107.24H320.34Z" />
        <path d="M227.77,415.83h-63.03c-4.94,0-9.64-2.12-12.91-5.81l-17.19-19.43c-2.56-2.9-6.25-4.55-10.11-4.55s-7.55,1.66-10.11,4.55l-14.75,16.67c-4.83,5.45-11.76,8.58-19.05,8.58h-.98c43.91,47.05,106.44,76.5,175.87,76.5s131.95-29.45,175.87-76.5H227.77Z" />
      </svg>
    </div>
  ),
};

const OPTIMISM_ETH = {
  s: (
    <div>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 500 500"
        width="100%"
        height="100%"
      >
        <circle fill="#FF0420" cx="250" cy="250" r="250" />
        <path
          fill="#FFFFFF"
          d="M177.1,316.4c-14.9,0-27.1-3.5-36.6-10.5c-9.4-7.1-14.1-17.3-14.1-30.4c0-2.8,0.3-6.1,0.9-10.1
        c1.6-9,3.9-19.8,6.9-32.5c8.5-34.4,30.5-51.6,65.9-51.6c9.6,0,18.3,1.6,25.9,4.9c7.6,3.1,13.6,7.9,18,14.3
        c4.4,6.3,6.6,13.8,6.6,22.5c0,2.6-0.3,5.9-0.9,9.9c-1.9,11.1-4.1,22-6.8,32.5c-4.4,17.1-11.9,30-22.7,38.5
        C209.5,312.3,195.1,316.4,177.1,316.4z M179.8,289.4c7,0,12.9-2.1,17.8-6.2c5-4.1,8.6-10.4,10.7-19c2.9-11.8,5.1-22,6.6-30.8
        c0.5-2.6,0.8-5.3,0.8-8.1c0-11.4-5.9-17.1-17.8-17.1c-7,0-13,2.1-18,6.2c-4.9,4.1-8.4,10.4-10.5,19c-2.3,8.4-4.5,18.6-6.8,30.8
        c-0.5,2.5-0.8,5.1-0.8,7.9C161.7,283.7,167.8,289.4,179.8,289.4z"
        />
        <path
          fill="#FFFFFF"
          d="M259.3,314.6c-1.4,0-2.4-0.4-3.2-1.3c-0.6-1-0.8-2.1-0.6-3.4l25.9-122c0.2-1.4,0.9-2.5,2.1-3.4
        c1.1-0.9,2.3-1.3,3.6-1.3H337c13.9,0,25,2.9,33.4,8.6c8.5,5.8,12.8,14.1,12.8,25c0,3.1-0.4,6.4-1.1,9.8c-3.1,14.4-9.4,25-19,31.9
        c-9.4,6.9-22.3,10.3-38.7,10.3h-25.3l-8.6,41.1c-0.3,1.4-0.9,2.5-2.1,3.4c-1.1,0.9-2.3,1.3-3.6,1.3H259.3z M325.7,242.9
        c5.3,0,9.8-1.4,13.7-4.3c4-2.9,6.6-7,7.9-12.4c0.4-2.1,0.6-4,0.6-5.6c0-3.6-1.1-6.4-3.2-8.3c-2.1-2-5.8-3-10.9-3h-22.5l-7.1,33.6
        H325.7z"
        />
      </svg>
    </div>
  ),
  m: (
    <div className="absolute top-0 overflow-hidden opacity-100">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 500 500"
      >
        <circle fill="#FF0420" cx="250" cy="250" r="250" />
        <path
          fill="#FFFFFF"
          d="M177.1,316.4c-14.9,0-27.1-3.5-36.6-10.5c-9.4-7.1-14.1-17.3-14.1-30.4c0-2.8,0.3-6.1,0.9-10.1
        c1.6-9,3.9-19.8,6.9-32.5c8.5-34.4,30.5-51.6,65.9-51.6c9.6,0,18.3,1.6,25.9,4.9c7.6,3.1,13.6,7.9,18,14.3
        c4.4,6.3,6.6,13.8,6.6,22.5c0,2.6-0.3,5.9-0.9,9.9c-1.9,11.1-4.1,22-6.8,32.5c-4.4,17.1-11.9,30-22.7,38.5
        C209.5,312.3,195.1,316.4,177.1,316.4z M179.8,289.4c7,0,12.9-2.1,17.8-6.2c5-4.1,8.6-10.4,10.7-19c2.9-11.8,5.1-22,6.6-30.8
        c0.5-2.6,0.8-5.3,0.8-8.1c0-11.4-5.9-17.1-17.8-17.1c-7,0-13,2.1-18,6.2c-4.9,4.1-8.4,10.4-10.5,19c-2.3,8.4-4.5,18.6-6.8,30.8
        c-0.5,2.5-0.8,5.1-0.8,7.9C161.7,283.7,167.8,289.4,179.8,289.4z"
        />
        <path
          fill="#FFFFFF"
          d="M259.3,314.6c-1.4,0-2.4-0.4-3.2-1.3c-0.6-1-0.8-2.1-0.6-3.4l25.9-122c0.2-1.4,0.9-2.5,2.1-3.4
        c1.1-0.9,2.3-1.3,3.6-1.3H337c13.9,0,25,2.9,33.4,8.6c8.5,5.8,12.8,14.1,12.8,25c0,3.1-0.4,6.4-1.1,9.8c-3.1,14.4-9.4,25-19,31.9
        c-9.4,6.9-22.3,10.3-38.7,10.3h-25.3l-8.6,41.1c-0.3,1.4-0.9,2.5-2.1,3.4c-1.1,0.9-2.3,1.3-3.6,1.3H259.3z M325.7,242.9
        c5.3,0,9.8-1.4,13.7-4.3c4-2.9,6.6-7,7.9-12.4c0.4-2.1,0.6-4,0.6-5.6c0-3.6-1.1-6.4-3.2-8.3c-2.1-2-5.8-3-10.9-3h-22.5l-7.1,33.6
        H325.7z"
        />
      </svg>
    </div>
  ),
};
