export type Asset = {
  id: string;
  network: string;
  asset: string;
}

export const getAssetsList = (walletAssets: any[] | undefined): Asset[] => {
  return walletAssets?.reduce((acc, asset) => {
    acc.push(...(asset?.data || []).map((assetData: any) => ({
      id: `${asset.network}-${assetData.asset}`,
      network: asset.network,
      ...assetData,
    })));
    return acc;
  }, []);
}
export const getAvailableAssets = (walletAssets: any[] | undefined, accounts: Record<string, any>): Asset[] => {
  const assets = getAssetsList(walletAssets);
  return assets?.filter((assetData: any) =>
    !accounts?.[assetData.network]?.assets.includes(assetData.asset)
  ) || [];
}

export const shouldShowNetwork = (asset: Pick<Asset, 'asset'>): boolean => {
  return asset.asset === 'eth';
}