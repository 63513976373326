import { useDispatch } from 'react-redux';
import ApproveTx from './ApproveTx';
import RejectTx from './RejectTx';
import { formatNetwork } from '../../../../utils/networkTools';
import { currentComponent, showModal } from '../../../../store/modal';
import { toast } from 'react-hot-toast';
const TxActions = ({ tx, user }) => {
  const dispatch = useDispatch();
  const canApprove = user.email !== tx.requestingManager;

  const displayApprove = () => {
    if (!canApprove) {
      return toast.error('Unauthorized.');
    }
    dispatch(
      currentComponent(() => (
        <ApproveTx
          id={tx.txId}
          owner={tx.ownerId}
          amt={`${tx.amount} ${formatNetwork(tx.network)}`}
        />
      )),
    );
    dispatch(showModal());
  };

  const displayReject = () => {
    dispatch(currentComponent(() => <RejectTx id={tx.txId} />));
    dispatch(showModal());
  };

  if (tx.approval.status !== 'pending') {
    return null;
  }

  return (
    <div className="flex justify-evenly space-x-10 text-slate-900">
      <button
        onClick={displayApprove}
        disabled={!canApprove}
        className={`font-semibold p-3 flex-1 text-center md:text-lg rounded cursor-pointer border border-transparent bg-neutral-200 ${canApprove ? 'hover:bg-neutral-100 hover:border-green-500' : 'opacity-50'}`}
      >
        Approve
      </button>
      <button
        onClick={displayReject}
        className="font-semibold p-3 flex-1 text-center md:text-lg rounded cursor-pointer border border-transparent bg-neutral-200 hover:bg-neutral-100 hover:border-red-500"
      >
        {user.email === tx.requestingManager ? 'Cancel' : 'Reject'}
      </button>
    </div>
  );
};

export default TxActions;
