import { useSelector } from 'react-redux';
import { XMarkIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactElement, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';

import TxActions from './actions/TxActions';
import capitalize from '../../../utils/capitalize';
import StatusTile from '../../../utils/StatusTile';
import { CopyToClipboard } from '../../../assets/icons/icons';
import { urls } from '../../../config';
import { httpGet } from '../../../store/axios';
import { Loading } from '../../../assets/icons/loading';
import { formatIntegerAmount } from '../../../utils/networkTools';
import { NetworkLogo } from '../../../assets/networks';

export type TransactionCardProps = {
  transaction: Record<string, any>;
  onClose: () => void;
};

type TransactionProps = {
  tx: Record<string, any>;
};

/**
 * @description - Construct a transaction card by inserting known transaction data.
 */
export function TransactionCard({ transaction, onClose }: TransactionCardProps): ReactElement {
  return (
    <div className="text-white backdrop-blur bg-opacity-90 min-w-fit text-xs sm:text-sm p-10 rounded-md bg-slate-800">
      <div className="flex justify-between">
        <div className="flex items-center space-x-2">
          <span className="sm:text-lg md:text-xl lg:text-2xl font-bold text-gray-100">
            Withdrawal Details
          </span>
          <div className="max-w-6">
            <NetworkLogo network={transaction?.network} token={transaction?.asset} size="s" />
          </div>
        </div>
        <XMarkIcon
          onClick={onClose}
          className="w-8 hover:cursor-pointer hover:stroke-2 hover:text-gray-400"
        />
      </div>
      <TransactionCardData tx={transaction} />
    </div>
  );
}

/**
 * @description - Construct a transaction card by querying the API for the transaction specified in the route parameter `id`.
 */
export function TransactionCardFromParams(): ReactElement {
  const { id } = useParams();
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState(null);
  useEffect(() => {
    httpGet(`${urls.transactionUrl}/status/${id}`)
      .then(res => setTransaction(res))
      .catch(err => toast.error(`${err}`));
  }, [id]);
  return TransactionCard({ transaction, onClose: () => navigate('/dashboard/withdrawals') });
}

function TransactionCardData({ tx }: { tx: Record<string, any> }) {
  if (!tx) {
    return <Loading />;
  }
  return (
    <>
      <div className="flex pt-2 space-x-2">
        <StatusTile name="Approval" status={tx.approval.status} />
        <StatusTile name="Withdrawal" status={tx.txProcessingStatus} />
      </div>
      <div className="py-8 text-sm text-gray-400">
        <table>
          <tbody>
            <TransactionMetadata tx={tx} />
            <tr className="h-5" />
            <WalletMetadata tx={tx} />
            <tr className="h-5" />
            <AssetMetadata tx={tx} />
          </tbody>
        </table>
      </div>
      <ApproveOrRejectActions tx={tx} />
    </>
  );
}

function AssetMetadata({ tx }: TransactionProps): ReactElement {
  return (
    <>
      <tr>
        <td>Asset</td>
        <td className="text-white">{tx.asset.toUpperCase()}</td>
      </tr>
      <tr>
        <td>Network</td>
        <td className="text-white">{capitalize(tx.network)}</td>
      </tr>
      <tr>
        <td>From</td>
        <td className="flex space-x-2">
          <span className="text-white">{tx.originAddress}</span>
          <span>
            <CopyToClipboard label="Origin address" text={tx.originAddress} />
          </span>
        </td>
      </tr>
      <tr>
        <td>To</td>
        <td className="flex space-x-2">
          <span className="text-white">{tx.destinationAddress}</span>
          <span>
            <CopyToClipboard label="Destination address" text={tx.destinationAddress} />
          </span>
        </td>
      </tr>
      <tr>
        <td>Value</td>
        <td className="text-white">{tx.amount}</td>
      </tr>
      <tr>
        <td>Fee</td>
        <td className="text-white">
          {tx.fee
            ? formatIntegerAmount(tx.fee, tx.network !== 'ethereum' ? tx.asset : 'eth')
            : null}
        </td>
      </tr>
    </>
  );
}

function TransactionMetadata({ tx }: TransactionProps): ReactElement {
  return (
    <>
      <tr>
        <td>Transaction ID</td>
        <td className="flex space-x-2">
          <span className="text-white">{tx.txId}</span>
          <CopyToClipboard label="Transaction ID" text={tx.txId} />
        </td>
      </tr>
      <tr>
        <td>Transaction Hash</td>
        <TransactionHashTd tx={tx} />
      </tr>
      <tr>
        <td className="pr-4">Transaction Status</td>
        <td className="text-white">{capitalize(tx.txProcessingStatus)}</td>
      </tr>
      <tr>
        <td>Date Created</td>
        <td className="text-white">{new Date(tx.dateCreated).toUTCString()}</td>
      </tr>
      <tr>
        <td className="flex space-x-1">
          <span>Date Processed</span>
          <TooltipOnHover message="The date the withdrawal was approved or rejected" />
        </td>
        <td className="text-white">
          {tx.dateProcessed ? new Date(tx.dateProcessed).toUTCString() : null}
        </td>
      </tr>
      <tr>
        <td className="flex space-x-1">
          <span>Date Verified</span>
          <TooltipOnHover message="The date the withdrawal was confirmed by Etana Digital" />
        </td>
        <td className="text-white">
          {tx.dateVerified ? new Date(tx.dateVerified).toUTCString() : null}
        </td>
      </tr>
      <tr>
        <td>Requested By</td>
        <td className="text-white">{tx.requestingManager}</td>
      </tr>
      <tr>
        <td>Approval Status</td>
        <td className="text-white">{capitalize(tx.approval.status)}</td>
      </tr>
      <tr>
        <td>Approved By</td>
        <td className="text-white">{tx.approval.approvedBy}</td>
      </tr>
    </>
  );
}

function TooltipOnHover({ message }: { message: string }): ReactElement {
  return (
    <div className="relative group">
      <InformationCircleIcon className="w-3" />
      <div className="absolute left-1/2 mb-2 bg-white text-black p-2 rounded w-max hidden group-hover:block transition-opacity duration-300 opacity-0 group-hover:opacity-100 z-10">
        {message}
      </div>
    </div>
  );
}

function TransactionHashTd({ tx }: TransactionProps): ReactElement {
  return (
    <td>
      <div className={`flex space-x-2 ${tx.txHash ? '' : 'hidden'}`}>
        <span className="text-white">
          {tx.explorerUrl ? (
            <a href={tx.explorerUrl} target="_blank" rel="noreferrer">
              {tx.txHash}
            </a>
          ) : (
            tx.txHash
          )}
        </span>
        <CopyToClipboard label="Transaction Hash" text={tx.txHash} />
      </div>
    </td>
  );
}

function WalletMetadata({ tx }: TransactionProps): ReactElement {
  return (
    <>
      <tr>
        <td>Wallet Owner</td>
        <td className="text-white">{tx.owner}</td>
      </tr>
      <tr>
        <td>Wallet ID</td>
        <td className="flex space-x-2">
          <span className="text-white">
            <a
              href={`/dashboard/wallets/${tx.walletId}`}
              className="text-blue-400 underline hover:text-blue-700"
            >
              {tx.walletId}
            </a>
          </span>
          <CopyToClipboard label="Wallet ID" text={tx.walletId} />
        </td>
      </tr>
    </>
  );
}

function ApproveOrRejectActions({ tx }: TransactionProps): ReactElement {
  const [managers, setManagers] = useState(null);
  const [err, setErr] = useState(null);
  const user = useSelector(state => (state as any).session.user); // TODO: typing of state?

  useEffect(() => {
    httpGet(`${urls.apiUrl}/wallets/id/${tx.walletId}/managers`)
      .then(res => setManagers(res.managers))
      .catch(e => setErr(e));
  }, [tx.walletId]);

  if (err) {
    toast.error('Failed to retrieve wallet metadata');
    return null;
  }

  if (!user.groups.includes('investors')) {
    return null;
  }

  if (!tx || tx.txProcessingStatus !== 'pending') {
    return null;
  }

  if (!managers) {
    return <Loading />;
  }

  if (!managers.includes(user.email)) {
    return (
      <div className="text-center text-gray-400">
        Only wallet managers can approve / reject transactions
      </div>
    );
  }

  return <TxActions tx={tx} user={user} />;
}
