import { useNavigate } from 'react-router-dom';
import { hideModal } from '../../../../store/modal';
import { useDispatch } from 'react-redux';
import { handleTxApprovalRejection } from '../../../../store/transactions';
import { toast } from 'react-hot-toast';

const RejectTx = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    const load = toast.loading('Submitting Rejection...');
    dispatch(handleTxApprovalRejection('reject', id))
      .then(res => {
        dispatch(hideModal());

        if (res && res[0]?.error) {
          toast.error('Error with rejecting transaction.');
          return;
        }

        toast.success('Transaction Rejected');
        navigate('/dashboard/withdrawals');
      })
      .catch(e => console.error(e))
      .finally(() => toast.dismiss(load));
  };

  return (
    <div className="bg-black bg-opacity-20 backdrop-blur-md rounded-md text-white p-8 space-y-8 max-w-[700px]">
      <h1 className="text-xl text-center">Reject withdrawal</h1>
      <div className="flex space-x-2 text-xs">
        <span className="opacity-60">Withdrawal ID:</span>
        <span>{id}</span>
      </div>
      <p>Are you sure you want to reject withdrawal {id}?</p>
      <div className="flex w-full justify-evenly">
        <button
          className="py-2 px-4 rounded-md border border-transparent cursor-pointer bg-red-700 hover:bg-red-600 hover:border-white"
          onClick={handleSubmit}
        >
          Reject
        </button>
        <button
          className="py-2 px-4 rounded-md border border-transparent cursor-pointer bg-gray-700 hover:bg-gray-600 hover:border-white"
          onClick={() => dispatch(hideModal())}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default RejectTx;
