import capitalize from './capitalize';
import {
  CheckIcon,
  EllipsisHorizontalIcon,
  LinkIcon,
  NoSymbolIcon,
  RocketLaunchIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { JSX } from 'react';

export type StatusTileProps = { name: string; status: string };

export function StatusTile({ name, status }: StatusTileProps): JSX.Element {
  const baseTile = 'py-1 px-3 rounded-sm text-xs flex items-center space-x-1';

  switch (status) {
    case 'approved': {
      return (
        <div className={`${baseTile} bg-green-600`}>
          <CheckIcon className="w-4" />
          <span>Approved</span>
        </div>
      );
    }
    case 'rejected': {
      return (
        <div className={`${baseTile} bg-red-500`}>
          <XMarkIcon className="w-4" />
          <span>Rejected</span>
        </div>
      );
    }
    case 'pending': {
      return (
        <div className={`${baseTile} bg-yellow-400 text-slate-900`}>
          <EllipsisHorizontalIcon className="w-4" />
          <span>{name} Pending</span>
        </div>
      );
    }
    case 'submitted': {
      return (
        <div className={`${baseTile} bg-blue-500`}>
          <RocketLaunchIcon className="w-4" />
          <span>Submitted to Blockchain</span>
        </div>
      );
    }
    case 'success': {
      return (
        <div className={`${baseTile} bg-green-600`}>
          <LinkIcon className="w-4" />
          <span>{name} Successful</span>
        </div>
      );
    }
    case 'failure': {
      return (
        <div className={`${baseTile} bg-red-500`}>
          <XMarkIcon className="w-4" />
          <span>{name} Failed</span>
        </div>
      );
    }
    case 'cancelled': {
      return (
        <div className={`${baseTile} bg-slate-900`}>
          <NoSymbolIcon className="w-4" />
          <span>{name} Cancelled</span>
        </div>
      );
    }
    default:
      return <div className={baseTile}>{capitalize(status)}</div>;
  }
}

export default StatusTile;
